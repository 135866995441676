const chatData = [
  {
    id: 1,
    sender: "Cameron Williamson",
    timeSent: "7:00AM",
    fileType: "text",
    message: "Hello, Check the files for the cleaning job",
    displayPic: "../displayPic.png",
  },
  {
    id: 2,
    sender: "Cameron Williamson",
    timeSent: "7:01AM",
    fileType: "file",
    message: "Details.zip",
    fileSize: "5.6MB",
    displayPic: "../displayPic.png",
  },
  {
    id: 3,
    sender: "Darlene Robertson",
    timeSent: "7:59AM",
    fileType: "text",
    message: "Thanks, Cameron. Could you share the date as well?",
    displayPic: "../display-picture.jpg",
  },
  {
    id: 4,
    sender: "Darlene Robertson",
    timeSent: "7:59AM",
    fileType: "text",
    message: "Thanks, Cameron. Could you share the date as well?",
    displayPic: "../display-picture.jpg",
  },
  {
    id: 5,
    sender: "Cameron Williamson",
    timeSent: "8:00AM",
    fileType: "text",
    message: "Okay it's on the 17th of next month",
    displayPic: "../displayPic.png",
  },
  {
    id: 6,
    sender: "Cameron Williamson",
    timeSent: "8:00AM",
    fileType: "text",
    message: "Check your mail",
    displayPic: "../displayPic.png",
  },
  // Add more chats as needed
];

const inboxSchema = [
  {
    id: 1,
    displayImage: "../displayPic.png",
    username: "Arvin Aradhana",
    text: "Are you busy right now?",
    timeSent: "8:00AM",
    numberOfMessagesSent: 2,
    lastSeen: "8:16AM",
  },
  {
    id: 2,
    displayImage: "../displayPic.png",
    username: "Cameron Williamson",
    text: "Check your mail",
    timeSent: "8:00AM",
    numberOfMessagesSent: 0,
    lastSeen: "8:16AM",
  },
  {
    id: 3,
    displayImage: "../displayPic.png",
    username: "Darlene Robertsons",
    text: "Can I book for the 15th",
    timeSent: "8:00AM",
    numberOfMessagesSent: 10,
    lastSeen: "8:16AM",
  },
  {
    id: 4,
    displayImage: "../displayPic.png",
    username: "Brooklyn Simmons",
    text: "Hello",
    timeSent: "8:00AM",
    numberOfMessagesSent: 2,
    lastSeen: "8:16AM",
  },
  {
    id: 5,
    displayImage: "../displayPic.png",
    username: "Darell Steward",
    text: "I need an apartment for my dog",
    timeSent: "8:00AM",
    numberOfMessagesSent: 2,
    lastSeen: "8:16AM",
  },
  {
    id: 6,
    displayImage: "../displayPic.png",
    username: "Jane Cooper",
    text: "Hi",
    timeSent: "8:00AM",
    numberOfMessagesSent: 2,
    lastSeen: "8:16AM",
  },
  {
    id: 7,
    displayImage: "../displayPic.png",
    username: "Ralph Edwards",
    text: "Show me some media",
    timeSent: "8:00AM",
    numberOfMessagesSent: 2,
    lastSeen: "8:16AM",
  },
  {
    id: 8,
    displayImage: "../displayPic.png",
    username: "Esther Howard",
    text: "We're here already",
    timeSent: "8:00AM",
    numberOfMessagesSent: 2,
    lastSeen: "8:16AM",
  },
];

export { chatData, inboxSchema };
