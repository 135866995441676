import * as React from "react";
import Dropdown from "@mui/joy/Dropdown";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import { SlOptions } from "react-icons/sl";
import axios from "axios";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import AppModal from "../modal/modal";
import { Stack, Typography } from "@mui/joy";
import AppButton from "../app-button/app-button.component";
import { useDispatch } from "react-redux";
import {
  deleteOneContractor,
  updateContractors,
} from "../../data/store/reducers/contractors";

export default function IconButtonMenu({ contractor }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const approveContractor = async () => {
    setLoading(true);
    console.log({ contractor });
    try {
      const res = await axios.post(
        `/admin/verify-contractor/${contractor._id}`
      );
      console.log({ res });
      dispatch(updateContractors(res.data?.contractor));
      toast.success(res.data?.success);
    } catch (error) {
      console.log({ error });
      toast.error(error.response?.data?.error);
    }
    setLoading(false);
  };
  const deleteContractor = async () => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `/admin/delete-contractor/${contractor._id}`
      );
      console.log({ res });
      toast.success(res.data.success);
      dispatch(deleteOneContractor(contractor));
      toggleModal();
    } catch (error) {
      console.log({ error });
      toast.error(error.response.data);
    }
    setLoading(false);
  };

  return (
    <>
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          sx={{ height: "fit-content", marginTop: 2 }}
          disabled={loading}
        >
          {loading ? <ClipLoader /> : <SlOptions />}
        </MenuButton>
        <Menu>
          <MenuItem
            onClick={approveContractor}
            disabled={contractor?.contractorAccountStatus === "active"}
          >
            {"Approve"}
          </MenuItem>
          <MenuItem onClick={toggleModal}>{"Delete"}</MenuItem>
        </Menu>
      </Dropdown>
      <AppModal isOpen={isOpen} close={toggleModal}>
        <Stack>
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            fontWeight="lg"
            mb={1}
            mt={4}
          >
            Are you sure you want to delete this user?
          </Typography>
          <Stack direction={"row"} gap={2} justifyContent={"center"} mt={4}>
            <AppButton
              buttonType={"primary"}
              text={"Yes"}
              onClick={deleteContractor}
              loading={loading}
            />
            <AppButton buttonType={"red"} text={"No"} onClick={toggleModal} />
          </Stack>
        </Stack>
      </AppModal>
    </>
  );
}
