import ApproveCompletedPage from "../../components/approve-complete-payment-page/approve-completed";
import Footer from "../../components/footer/footer";
import Sidebar from "../../components/sidebar/sidebar";

const pageDetails = {
  header: "Payment Received",
  body: "Thanks for your order, your payment has been received! We will contact you on the next steps",
  buttonText: "Continue",
  navigateTo: "/",
};

const PaymentApproved = () => {
  return (
    <Sidebar sidebarType={"reversed"}>
      <section className="h-screen flex items-center justify-center flex-col gap-4">
        <ApproveCompletedPage pageDetails={pageDetails} />
      </section>
      <footer>
        <Footer />
      </footer>
    </Sidebar>
  );
};

export default PaymentApproved;
