import React, { useState } from "react";
import AppButton from "../components/app-button/app-button.component";
import Input from "../components/input/input";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (data) => {
    console.log({ data });
    setLoading(true);
    try {
      const res = await axios.post("/send-forgot-password-link", data);
      toast.success(res.data.success);
    } catch (error) {
      console.log({ error });
      toast.error(error.response?.data?.error);
    }
    setLoading(false);
  };
  return (
    <div className="bg-[#F4F4F4] flex items-center justify-center flex-col gap-4 py-32 min-h-screen">
      <div className="bg-white p-6 rounded-lg">
        <form className="max-w-md mx-auto" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid sm:grid-cols-2 gap-4 min-w-72">
            <div className="col-span-2">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email format",
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    label={"Enter you email"}
                    placeholder="Email"
                    type={"email"}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {errors.email && (
                <p className="text-red text-xs">This field is required.</p>
              )}
            </div>
          </div>
          {/* <div className="mt-4">
            <p className="text-[#5E5D60] text-sm text-center max-w-md mx-auto">
              By creating an account you agree to our Agreement privacy policy
              and Cookie policy
            </p>
          </div> */}
          <div className="mt-4">
            <AppButton
              text={"Send"}
              buttonType={"pill"}
              width={"full"}
              type="submit"
              loading={loading}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
