import React from "react";
import DashbboardHeader from "../components/dashboard-header/dahboard-header";
import IconButtonMenu from "../components/icon-menu/IconButtonMenu";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { selectContractors } from "../data/store/selectors/contractorSelector";
import Sidebar from "../components/sidebar/sidebar";

const Contractors = () => {
  const navigate = useNavigate();
  const contractors = useSelector(selectContractors);
  //   .filter(
  //     (item) => item.userType === "contractor"
  //   );
  console.log({ contractors });
  return (
    <Sidebar>
      <DashbboardHeader />
      <div className="min-h-screen pt-16 md:pt-28 pb-20 px-8 bg-[#FAF9FF]">
        <div className="bg-white p-4">
          <h1 className="text-2xl font-semibold text-[#353535]">
            Current Contractors
          </h1>
          <p className="text-sm text-[#AEAEAE] max-w-md mt-2">
            Below is a list of all contractors currently in the system.
            {/* You can view
            details, edit information, or remove contractors as needed */}
          </p>
          <div className="flex items-center justify-between mt-4">
            <p className="text-[#AEAEAE] text-lg">
              Total Contractors:{" "}
              <span className="text-black">{contractors?.length}</span>
            </p>
            {/* <AppButton
              buttonType={"outlineMain"}
              text={"Add Contractor"}
              onClick={() => navigate("add-contractor")}
            /> */}
          </div>

          <div className="relative overflow-x-auto mt-8">
            <table className="w-full text-sm text-left rtl:text-right">
              <thead className="">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    {/* <span className="sr-only">Edit</span> */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {contractors?.map((contractor) => (
                  <tr className="bg-white">
                    <th scope="row" className="px-6 py-4 whitespace-nowrap">
                      {contractor.lastName} {contractor.firstName}
                    </th>
                    <td className="px-6 py-4">{contractor.email}</td>
                    <td className="px-6 py-4">
                      {contractor?.contractorAccountStatus}
                    </td>
                    <td className="px-6 py-4">
                      <IconButtonMenu contractor={contractor} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default Contractors;
