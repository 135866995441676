import { useSelector } from "react-redux";
import Footer from "../../components/footer/footer";
import Quotes from "../../components/quotes/quotes";
import Sidebar from "../../components/sidebar/sidebar";
import {
  quoteLoading,
  selectQuotes,
} from "../../data/store/selectors/quoteSelector";

const PendingRequests = () => {
  const quotes = useSelector(selectQuotes);
  const loading = useSelector(quoteLoading);
  return (
    <Sidebar sidebarType={"reversed"}>
      <section className="max-w-[90%] lg:max-w-[66rem] mx-auto mt-6 mb-10 lg:mt-10 lg:mb-16">
        <Quotes quotes={quotes} loading={loading} route={"/pending-requests"} />
      </section>
      <footer>
        <Footer />
      </footer>
    </Sidebar>
  );
};

export default PendingRequests;
