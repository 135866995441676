import React from "react";

import { IoStar } from "react-icons/io5";

const TestimonialCard = () => {
  return (
    <div
      className="p-6 rounded-2xl bg-white text-sm border border-text"
      //   style={{ width: "24rem" }}
    >
      <div className="w-80">
        <p className="text-[17px]">
          "Does exactly what it says. Clear to read and understand. This is now
          the second iPhone we’ve used it on and would certainly recommend this
          app."
        </p>
        <div className="flex items-center mt-8 gap-3">
          <img
            src="../display-picture.jpg"
            alt=""
            className="rounded-full h-12 w-12"
          />
          <div>
            <p className="font-bold text-[#02033B] text-lg">Ella A. George</p>
            <p className="text-sm">Property owner</p>
          </div>
        </div>
        <div className="flex gap-1 text-[#FBBB5D] text-sm mt-3">
          <IoStar />
          <IoStar />
          <IoStar />
          <IoStar />
          <IoStar />
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
