import { Tabs } from "@mui/base/Tabs";
import { styled } from "@mui/system";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";

const AppTabs = ({ tabs }) => {
  return (
    <Tabs defaultValue={0}>
      <TabsList>
        {tabs?.map((tab, idx) => (
          <Tab value={idx} key={tab.id}>
            {tab?.name}
          </Tab>
        ))}
        {/* <Tab value={1}>Profile</Tab>
        <Tab value={2}>Language</Tab> */}
      </TabsList>
      {tabs?.map((tab, idx) => (
        <TabPanel value={idx} key={tab.id}>
          {tab?.component}
        </TabPanel>
      ))}
      {/* <TabPanel value={1}>Profile page</TabPanel>
      <TabPanel value={2}>Language page</TabPanel> */}
    </Tabs>
  );
};

export default AppTabs;

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Tab = styled(BaseTab)`
  font-family: "IBM Plex Sans", sans-serif;
  color: #737373;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #cfdbd522;
  }

  &:focus {
    color: #cfdbd5;
    // outline: 3px solid ${blue[200]};
  }

  &.${tabClasses.selected} {
    background-color: #cfdbd5;
    color: #737373;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    padding: 0 12px;
    background: "${theme.palette.mode === "dark" ? grey[900] : "#fff"}";
    //border: 1px solid ${
      theme.palette.mode === "dark" ? grey[700] : grey[200]
    };
    border-radius: 12px;
    // opacity: 0.6;
    `
);

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
    min-width: 300px;
    background-color: #F1F4F2;
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    `
);
