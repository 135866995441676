const getContentClass = (showAvatar, alignRight) => {
  return `flex flex-col ${alignRight ? "items-end" : "items-start"} ${
    showAvatar && !alignRight ? "md:ml-4" : !alignRight ? "md:ml-[75px]" : ""
  } ${showAvatar && alignRight ? "md:mr-4" : alignRight ? "md:mr-[75px]" : ""}`;
};

const getBorderClasses = (showAvatar, alignRight) => {
  return alignRight
    ? `border-2 border-main ${
        showAvatar ? "rounded-l-xl rounded-br-xl" : "rounded-xl"
      }`
    : `border-2 border-main ${
        showAvatar ? "rounded-r-xl rounded-bl-xl" : "rounded-xl"
      }`;
};

const getMarginClasses = (showAvatar, isLastInGroup) => {
  return showAvatar ? "" : isLastInGroup ? "mb-6" : "mb-1";
};

export { getContentClass, getBorderClasses, getMarginClasses };
