import React from 'react'
import { useParams } from 'react-router-dom'

const PageSub = () => {
    const {id} = useParams()
  return (
    <div>PageSub {id}</div>
  )
}

export default PageSub