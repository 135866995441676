import { createSelector } from "@reduxjs/toolkit";

export const quoteSelector = (state) => state?.quote;

export const selectQuotes = createSelector(
  [quoteSelector],
  (quotes) => quotes?.quotes
);

export const selectAllQuotes = createSelector(
  [quoteSelector],
  (quotes) => quotes?.allQuotes
);

export const quoteLoading = createSelector(
  [quoteSelector],
  (loading) => loading?.loading
);

export const quoteError = createSelector(
  [quoteSelector],
  (error) => error?.error
);
