import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";

import AppButton from "../components/app-button/app-button.component";
import { ReactComponent as Google } from "../assets/svg/google.svg";
import Input from "../components/input/input";
import { useDispatch } from "react-redux";
import { loadUser, login } from "../data/store/reducers/userSlice";
import axios from "axios";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    console.log({ data });
    try {
      const res = await axios.post("/auth/signin", data);
      console.log({ res });
      const user = await res.data.user;
      dispatch(login(user));
      dispatch(loadUser());
      navigate("/");
      toast.success("login successful");
    } catch (error) {
      console.log({ error });
      toast.error(error.response?.data?.error);
    }
    setLoading(false);
  };

  return (
    <div className="h-screen bg-[#F4F4F4] flex items-center justify-center flex-col gap-4">
      <h1 className="text-2xl text-center">Sign back into your account</h1>
      <div className="bg-white p-6 rounded-lg">
        <form className="max-w-md mx-auto" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid sm:grid-cols-2 gap-4">
            <div className="col-span-2">
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email format",
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    label={"Enter you email"}
                    placeholder="Email"
                    type={"email"}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {errors.email && (
                <p className="text-red text-xs">This field is required.</p>
              )}
            </div>
            <div className="col-span-2">
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "This field is required",
                  // pattern: {
                  //   value:
                  //     /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
                  //   message:
                  //     "Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
                  // },
                }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    label={"Password (6 or more characters)"}
                    placeholder="Password"
                    type={"password"}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {errors.password && (
                <p className="text-red text-xs">{errors.password.message}</p>
              )}
            </div>
          </div>
          <div className="text-right">
            <Link
              to={"/forgot-password"}
              className="italic text-right text-xs text-blue-500"
            >
              Forgot Password
            </Link>
          </div>
          <div className="mt-4">
            <p className="text-[#5E5D60] text-sm text-center max-w-md mx-auto">
              By creating an account you agree to our Agreement privacy policy
              and Cookie policy
            </p>
          </div>
          <div className="mt-4">
            <AppButton
              text={"Sign in"}
              loading={loading}
              buttonType={"pill"}
              width={"full"}
            />
          </div>
          <div className="text-center mt-4">
            <p className="text-md text-text">
              New here?{" "}
              <span
                className="font-bold text-main hover:text-yellow-600 cursor-pointer"
                onClick={() => navigate("/register")}
              >
                Create a free account
              </span>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
