import { combineReducers } from "redux";
import userSlice from "./userSlice";
import serviceSlice from "./serviceSlice";
import quoteSlice from "./quoteSlice";
import adminSlice from "./adminSlice";
import contractors from "./contractors";
import tenantSlice from "./tenantSlice";

const rootReducer = combineReducers({
  user: userSlice,
  service: serviceSlice,
  quote: quoteSlice,
  admin: adminSlice,
  contractor: contractors,
  tenant: tenantSlice,
});

export default rootReducer;
