import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Input from "../../components/input/input";
import AppButton from "../../components/app-button/app-button.component";
import ApproveCompletedPage from "../../components/approve-complete-payment-page/approve-completed";
import Footer from "../../components/footer/footer";
import Sidebar from "../../components/sidebar/sidebar";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setService } from "../../data/store/reducers/serviceSlice";
import { updateQuote } from "../../data/store/reducers/quoteSlice";

const CreateQuote = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stage = location.search.split("=")[1];
  console.log({ stage: location.state });

  // useEffect(() => {
  //   if (!location.search) {
  //     navigate(`/post-a-request/service-details?stage=1`);
  //   }
  // }, [location.search, navigate, stage]);

  return (
    <>
      {stage === "1" ? (
        <DetailsStage1 service={location.state} />
      ) : stage === "2" ? (
        <DetailsStage2 service={location.state} />
      ) : (
        stage === "success" && <Success />
      )}
    </>
  );
};

export default CreateQuote;

const DetailsStage1 = ({ service }) => {
  const navigate = useNavigate();

  const [details, setDetails] = useState({
    // currency: "",
    estimatedCost: "",
    description: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  return (
    <Sidebar sidebarType={"reversed"}>
      <section className="my-10 md:my-16 mx-auto md:mx-44 w-[80%] md:max-w-md">
        <div>
          <h1 className="font-semibold text-2xl">Propose an Offer</h1>
          <p className="text-text text-[15px] my-4 font-medium">
            If the original terms don't work for you, propose new terms for the
            client to review. Explain your reasoning to help the client
            understand your perspective
          </p>
          <div className="flex items-center gap-4 my-8">
            <div className="w-[60%] md:w-[70%]">
              <Input
                label={"Estimated Costs (PHP)"}
                placeholder="3000"
                type="text"
                value={details.estimatedCost}
                name={"estimatedCost"}
                onChange={handleInput}
              />
            </div>
          </div>
          <div className="mb-8">
            <Input
              label={"Describe your service"}
              type={"textArea"}
              placeholder="Give specific details that will describe your needs properly"
              value={details.description}
              name={"description"}
              onChange={handleInput}
            />
            <p className="text-text text-xs my-1 text-right">
              5,000 characters left
            </p>
          </div>
          <AppButton
            buttonType={"pill"}
            text={"continue"}
            type="submit"
            width={"full"}
            onClick={() =>
              navigate("/pending-requests/create-quote?stage=2", {
                state: { details, service },
              })
            }
          />
        </div>
      </section>
    </Sidebar>
  );
};

const DetailsStage2 = ({ service }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  console.log({ service });

  const form = useLocation().state.details;
  const [details, setDetails] = useState({
    availableFromDate: service?.service?.availableFromDate.split("T")[0] || "",
    availableToDate: service?.service?.availableToDate.split("T")[0] || "",
    availableFromTime: service?.service?.availableFromTime || "",
    availableToTime: service?.service?.availableToTime || "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const handleSubmit = async () => {
    const data = { ...form, ...details };
    console.log({ data });
    setLoading(true);
    try {
      const res = await axios.post(
        `/contractor/create-quote/${service?.service._id}`,
        data
      );
      console.log({ res });
      // dispatch(updateQuote(res.data.quote));
      toast.success(res.data.success);
      navigate(`/pending-requests/create-quote?stage=success`);
    } catch (error) {
      console.log({ error });
      toast.error(error.response?.data?.error);
    }
    setLoading(false);
  };

  return (
    <Sidebar sidebarType={"reversed"}>
      <section className="my-10 md:my-16 mx-auto md:mx-44 w-[80%] md:max-w-md">
        <h1 className="font-semibold text-2xl">Select Availability</h1>
        <p className="text-text text-[15px] font-medium mt-4 mb-8">
          Let us know when you are available for the service appointment, and
          we'll find a time that works best for you
        </p>
        <div className="grid grid-cols-2 gap-4">
          <Input
            type={"datePicker"}
            label={"Available from"}
            value={details.availableFromDate}
            name={"availableFromDate"}
            onChange={handleInput}
          />
          <Input
            type={"datePicker"}
            label={"Available to"}
            value={details.availableToDate}
            name={"availableToDate"}
            onChange={handleInput}
          />
          <Input
            type={"timePicker"}
            label={"From"}
            value={details.availableFromTime}
            name={"availableFromTime"}
            onChange={handleInput}
          />
          <Input
            type={"timePicker"}
            label={"To"}
            value={details.availableToTime}
            name={"availableToTime"}
            onChange={handleInput}
          />
        </div>
        <div className="my-8">
          <AppButton
            buttonType={"pill"}
            text={"continue"}
            type="submit"
            width={"full"}
            onClick={handleSubmit}
            loading={loading}
          />
        </div>
      </section>
    </Sidebar>
  );
};

const Success = () => {
  const pageDetails = {
    header: "Thank you for your request",
    body: "We have received your Quotes. Our team will review your request and get back to you shortly",
    buttonText: "Continue",
    navigateTo: "/",
  };

  return (
    <Sidebar sidebarType={"reversed"}>
      <section className="min-h-screen flex flex-col justify-center items-center">
        <ApproveCompletedPage pageDetails={pageDetails} />
      </section>
      <footer>
        <Footer />
      </footer>
    </Sidebar>
  );
};

export { DetailsStage1, DetailsStage2, Success };
