import DashbboardHeader from "../components/dashboard-header/dahboard-header";
import Sidebar from "../components/sidebar/sidebar";
import Calender from "../components/calender/calender";
import { useSelector } from "react-redux";
import {
  contractorLoading,
  selectContractors,
} from "../data/store/selectors/contractorSelector";
import { ClipLoader } from "react-spinners";
import IconButtonMenu from "../components/icon-menu/IconButtonMenu";
import { All } from "../contractor";
import { selectServices } from "../data/store/selectors/serviceSelector";
import AppTabs from "../components/tab/Tabs";

const Admin = () => {
  const contractors = useSelector(selectContractors);

  const services = useSelector(selectServices);
  const loading = useSelector(contractorLoading);
  const tabs = [
    {
      id: 1,
      name: "All",
      component: <All services={services} status={"all"} />,
    },
    {
      id: 2,
      name: "Ongoing",
      component: <All services={services} status={"ongoing"} />,
    },
    {
      id: 3,
      name: "Pending",
      component: <All services={services} status={"pending"} />,
    },
    {
      id: 4,
      name: "Completed",
      component: <All services={services} status={"completed"} />,
    },
    {
      id: 5,
      name: "Cancelled",
      component: <All services={services} status={"cancelled"} />,
    },
  ];

  return (
    <Sidebar>
      <DashbboardHeader />
      <div className="min-h-screen pt-16 md:pt-28 pb-20 px-8">
        <h3 className="text-[#CCCCCC] text-sm">
          Welcome back{" "}
          <span className="font-bold text-xl text-[#353535] ml-1">Admin</span>
        </h3>
        <div className="grid lg:grid-cols-5 gap-y-6 lg:gap-x-5 xl:gap-x-16">
          <div className="py-6 col-span-3">
            <div className="flex justify-between items-center mb-4">
              <h2 className="font-semibold">Contractors</h2>
              <select
                name="soa"
                id="soa"
                className="bg-transparent text-dark outline-none text-sm font-medium"
              >
                <option>Filter</option>
                <option value="house1">Tenants</option>
              </select>
            </div>
            <div className="border border-gray rounded-xl px-4 md:px-6">
              {loading && (
                <div className="text-center">
                  <ClipLoader />
                </div>
              )}
              {contractors.map((contractor) => (
                <AdminCards contractor={contractor} icon />
              ))}
            </div>
          </div>
          <div className="mt-3 w-fit">
            <Calender />
          </div>
        </div>
        <section className="my-10">
          <p className="font-medium text-lg">Bookings</p>
          <div className="border border-[#105A5D1A] p-4 rounded-xl mt-3">
            <AppTabs tabs={tabs} />
          </div>
        </section>
      </div>
    </Sidebar>
  );
};

export default Admin;

export const AdminCards = ({ contractor, icon, active, setActive }) => {
  console.log({ contractor });

  const handleClick = () => {
    if (active === contractor._id) {
      setActive("");
    } else {
      setActive(contractor?._id);
    }
  };
  return (
    <div
      className={`flex justify-between border rounded-lg p-4 ${
        active === contractor?._id ? "border-black" : "border-transparent"
      } ${setActive && "cursor-pointer"}`}
      onClick={setActive && handleClick}
    >
      <div className="flex gap-5 border-b border-gray py-7">
        <img
          src={require("../assets/img/display-picture.jpg")}
          alt="userPicture"
          className="h-12 w-12 rounded-full"
        />
        <div>
          <h2 className="font-semibold">
            {contractor?.firstName} {contractor?.lastName}
          </h2>
          <p className="text-text text-sm font-medium">
            {contractor?.categories.map(
              (item, idx) =>
                item + (idx === contractor.categories.length - 1 ? "" : " | ")
            )}
          </p>

          <p className="text-text text-sm font-medium mt-3 flex gap-4">
            <span>
              Status:{" "}
              <span className="text-black font-bold capitalize">
                {contractor?.contractorAccountStatus}
              </span>
            </span>
            |
            <span>
              Services Completed:{" "}
              <span className="text-black font-bold">
                {contractor?.completedServicesCount}
              </span>
            </span>
          </p>
        </div>
      </div>
      {icon && <IconButtonMenu contractor={contractor} />}
    </div>
  );
};
