const ChooseRole = ({ value, state, toggleState }) => {
  return (
    <div
      className={`w-full border border-main rounded-full px-4 text-sm font-semibold capitalize py-3 text-center cursor-pointer ${
        state ? "bg-[#F7F7F7] text-main" : "text-main"
      }`}
      onClick={toggleState}
    >
      {value}
    </div>
  );
};

export default ChooseRole;
