import { ReactComponent as Link } from "../../assets/svg/link.svg";
import { ReactComponent as Emoji } from "../../assets/svg/emoji.svg";
import { ReactComponent as Microphone } from "../../assets/svg/microphone.svg";

const MessageInput = ({ inputValue, handleKeyDown, setInputValue }) => {
  return (
    <div className="fixed w-full bottom-0 left-0 lg:w-3/4 lg:left-1/4 z-10 bg-white px-6 lg:px-14 py-2 border-t border-lightGray">
      <div className="flex items-center justify-between gap-2 lg:gap-6 mx-auto">
        <div className="flex-grow-0">
          <Link className="cursor-pointer" />
        </div>
        <div className="flex-grow">
          <input
            type="text"
            placeholder="Type a message here..."
            className="w-full py-2 lg:py-6 outline-none"
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            value={inputValue}
          />
        </div>
        <div className="flex items-center flex-grow-0 gap-2 lg:gap-6">
          <Emoji className="cursor-pointer" />
          <Microphone className="cursor-pointer" />
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
