import DashbboardHeader from "../components/dashboard-header/dahboard-header";
import Sidebar from "../components/sidebar/sidebar";

const tableData = [
  {
    service: "Room cleaning",
    client: "Sproto Pepes",
    id: "#ABDD2",
    price: "$300",
    date: "11-14/04/2024",
    status: "Completed",
  },

  {
    service: "Electrical fitting",
    client: "James Gunn",
    id: "#ABDD2",
    price: "$300",
    date: "11-14/04/2024",
    status: "Completed",
  },
  {
    service: "Room cleaning",
    client: "Sproto Pepes",
    id: "#ABDD2",
    price: "$300",
    date: "11-14/04/2024",
    status: "Ongoing",
  },
  {
    service: "Room cleaning",
    client: "Sproto Pepes",
    id: "#ABDD2",
    price: "$300",
    date: "11-14/04/2024",
    status: "Cancelled",
  },
];

const PaymentsHistory = () => {
  return (
    <Sidebar>
      <DashbboardHeader />
      <section className="min-h-screen bg-[#F9F8FF] lg:flex lg:justify-center lg:items-center py-16 lg:py-32">
        <div className="lg:w-[90%] bg-white text-black py-10 px-8 lg:rounded-xl">
          <h1 className="text-3xl text-dark font-bold">Order Management</h1>
          <p className="text-text mt-2 font-medium text-sm xl:max-w-[66%]">
            Hello Heritage! Welcome to your order management dashboard. Here's a
            summary of your recent orders, including completed jobs and ongoing
            projects
          </p>
          <div>
            <div className="flex justify-between items-center">
              <h3 className="text-lg md:text-xl text-dark font-bold my-8">
                Total Orders: 16
              </h3>
              <select
                name="month"
                id="month"
                className="bg-transparent outline-none text-dark font-medium"
              >
                <option value="this-month">This month</option>
              </select>
            </div>
            <div className="w-full overflow-x-auto">
              <table className="w-full space-y-8 whitespace-nowrap">
                <tr className="font-semibold text-left text-dark">
                  <th>Service</th>
                  <th>Client</th>
                  <th>ID</th>
                  <th>Price</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
                {tableData.map((data, idx) => {
                  const { client, service, id, price, date, status } = data;
                  return (
                    <tr key={idx} className="text-sm text-text font-medium">
                      <td className="py-5 pr-6 lg:pr-0">{service}</td>
                      <td className="py-5 pr-6 lg:pr-0">{client}</td>
                      <td className="py-5 pr-6 lg:pr-0">{id}</td>
                      <td className="py-5 pr-6 lg:pr-0">{price}</td>
                      <td className="py-5 pr-6 lg:pr-0">{date}</td>
                      <td
                        className={`py-5 ${
                          status === "Completed"
                            ? "text-[#80D48D]"
                            : status === "Ongoing"
                            ? "text-[#F7D3BF]"
                            : "text-red"
                        }`}
                      >
                        {status}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </section>
    </Sidebar>
  );
};

export default PaymentsHistory;
