import {
  getContentClass,
  getMarginClasses,
  getBorderClasses,
} from "../../utils/contentClasses";

const Message = ({ chat, properties, children }) => {
  const { sender, timeSent, displayPic, isLastInGroup } = chat;
  const { showAvatar, showTime, alignRight } = properties;

  return (
    <div
      className={`flex ${
        alignRight ? "justify-end" : "justify-start"
      } ${getMarginClasses(showAvatar, isLastInGroup)}`}
    >
      {showAvatar && !alignRight && <Avatar src={displayPic} alt={sender} />}
      <div className={getContentClass(showAvatar, alignRight)}>
        {showAvatar && (
          <h2 className="font-medium mb-2 text-sm">
            {sender === "Darlene Robertson" ? "You" : sender}
          </h2>
        )}
        <div className={showAvatar && "mb-2"}>{children}</div>
        {showTime && <p className="text-text text-sm mt-3">{timeSent}</p>}
      </div>
      {showAvatar && alignRight && <Avatar src={displayPic} alt={sender} />}
    </div>
  );
};

export default Message;

const ChatBubble = ({ chat, showAvatar, alignRight }) => {
  const { fileType, message, fileSize } = chat;

  return (
    <>
      {fileType === "text" ? (
        <div
          className={`${getBorderClasses(
            showAvatar,
            alignRight
          )} p-4 text-text md:max-w-[380px] md:min-w-[380px]`}
        >
          <p className="text-dark">{message}</p>
        </div>
      ) : (
        <div className="flex p-5 border-2 border-main rounded-xl max-w-fit">
          <div className="flex gap-4">
            <div className="bg-text w-12"></div>
            <div className="space-y-1">
              <p className="text-dark font-medium text-[16px]">{message}</p>
              <p className="text-text text-sm">{fileSize}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { ChatBubble };

const Avatar = ({ src, alt }) => (
  <img
    src={src}
    alt={alt}
    className="max-w-[58px] min-w-[58px] max-h-[58px] min-h-[58px] rounded-full object-cover object-center hidden md:block"
  />
);
