/* eslint-disable react/prop-types */
import { useState } from "react";
import { createContext } from "react";
import { BiCategoryAlt } from "react-icons/bi";
import { ReactComponent as DashboardIcon } from "../assets/svg/dashboard.svg";
import { ReactComponent as PendingRequestsIcon } from "../assets/svg/search-normal.svg";
import { ReactComponent as NoteIcon } from "../assets/svg/note.svg";
import { ReactComponent as BuildingIcon } from "../assets/svg/building-02.svg";
import { ReactComponent as UsersIcon } from "../assets/svg/user-multiple.svg";
import { ReactComponent as PostARequestIcon } from "../assets/svg/megaphone.svg";
import { ReactComponent as InboxIcon } from "../assets/svg/sms.svg";
import { ReactComponent as Travel } from "../assets/svg/travel.svg";
import { ReactComponent as Note } from "../assets/svg/note.svg";

export const GlobalState = createContext();

const DataProvider = ({ children }) => {
  const [nav, setNav] = useState(false);
  const [choice, setChoice] = useState();

  const handleCapitalize = (word) => {
    const splitter = word.trim().split(" ");
    const firstCap = splitter[0].split("");
    const remaining = splitter.slice(1, splitter.length).join(" ");

    const firstCapOne = firstCap[0].toUpperCase();
    const firstCapTwo = firstCap.slice(1, firstCap.length).join("");

    const joinFirst = `${firstCapOne}${firstCapTwo}`;

    return `${joinFirst} ${remaining}`;
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const nairaSignNeutral = "₦";
  const nairaSign = <span className="fontInherit">&#8358;</span>;

  const toggleNav = () => {
    setNav(!nav);
  };

  let sidebarList = [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: <BiCategoryAlt className="icon" size={24} />,
      permission: ["admin", "user"],
    },
    {
      name: "Registered Vehicle",
      url: "/registered",
      icon: <BiCategoryAlt className="icon" size={24} />,
      permission: ["admin", "user"],
    },
    {
      name: "Admin",
      url: "/admin",
      icon: <BiCategoryAlt className="icon" size={24} />,
      permission: ["admin", "user"],
    },
    {
      name: "Profile",
      url: "/profile",
      icon: <BiCategoryAlt className="icon" size={24} />,
      permission: ["admin", "user"],
    },
  ];

  const sidebarLinks = {
    admin: [
      { name: "Dashboard", url: "/", icon: <DashboardIcon /> },
      {
        name: "Pending Requests",
        url: "/pending-requests",
        icon: <Travel />,
      },
      {
        name: "Contractor Quotes",
        url: "/pending-quotes",
        icon: <NoteIcon />,
      },
      {
        name: "Tenants",
        url: "/tenants",
        icon: <BuildingIcon />,
      },
      {
        name: "Contractors",
        url: "/contractors",
        icon: <UsersIcon />,
      },
      // {
      //   name: "Payments History",
      //   url: "/payments-history",
      //   icon: <Note />,
      // },
    ],
    tenant: [
      {
        name: "Dashboard",
        url: "/",
        icon: <DashboardIcon />,
      },
      {
        name: "Pending Requests",
        url: "/pending-requests",
        icon: <Travel />,
      },
      {
        name: "Post a Request",
        url: "/post-a-request",
        icon: <PostARequestIcon />,
      },
      // { name: "Inbox", url: "/inbox", icon: <InboxIcon /> },
    ],
    contractor: [
      { name: "Dashboard", url: "/", icon: <DashboardIcon /> },
      {
        name: "Pending Requests",
        url: "/pending-requests",
        icon: <Travel />,
      },
      // { name: "Inbox", url: "/inbox", icon: <InboxIcon /> },
    ],
    owner: [
      {
        name: "Dashboard",
        url: "/",
        icon: <DashboardIcon />,
      },
      {
        name: "Pending Requests",
        url: "/pending-requests",
        icon: <Travel />,
      },
      {
        name: "Post a Request",
        url: "/post-a-request",
        icon: <PostARequestIcon />,
      },
      { name: "Inbox", url: "/inbox", icon: <InboxIcon /> },
    ],
  };

  const state = {
    handleCapitalize,

    numberWithCommas,
    sidebarList,
    sidebarLinks,
    nav,
    choice,
    setChoice,
    setNav,
    toggleNav,
    nairaSignNeutral,
    nairaSign,
  };

  return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default DataProvider;
