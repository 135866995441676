import { ClipLoader } from "react-spinners";

const BUTTON_TYPE_CLASSES = {
  primary: "bg-main text-white rounded-md",
  red: "bg-[#FF0000] text-white rounded-md",
  white: "bg-white text-black rounded-md",
  outline: "bg-transparent border border-text-white text-white rounded-md",
  outlineMain: "bg-transparent border-2 border-main text-main rounded-md",
  outlineBlack: "bg-transparent border-2 border-text text-text rounded-md",
  pill: "bg-main text-white rounded-full",
};

const BUTTON_WIDTH = {
  full: "w-full",
};

const AppButton = ({ buttonType, text, width, loading, ...otherProps }) => {
  return (
    <button
      className={`p-2 ${
        width ? width : "min-w-32"
      } font-bold text-sm h-10 whitespace-nowrap capitalize ${
        BUTTON_TYPE_CLASSES[buttonType]
      } ${BUTTON_WIDTH[width]}`}
      disabled={loading}
      {...otherProps}
    >
      {loading ? (
        <span className="flex items-center justify-center w-full">
          <ClipLoader size={20} />
        </span>
      ) : (
        text
      )}
    </button>
  );
};

export default AppButton;
