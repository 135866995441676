import Sidebar from "../components/sidebar/sidebar";
import OrdersCard from "../components/orders-card/orders-card";
import Calender from "../components/calender/calender";
import AppTabs from "../components/tab/Tabs";
import DoughnutChart from "../components/donut-chart/donut-chart";
import DashbboardHeader from "../components/dashboard-header/dahboard-header";
import { useSelector } from "react-redux";
import { selectServices } from "../data/store/selectors/serviceSelector";

const Contractor = () => {
  const services = useSelector(selectServices);
  console.log({ services });
  const tabs = [
    {
      id: 1,
      name: "All",
      component: <All services={services} status={"all"} />,
    },
    {
      id: 2,
      name: "Ongoing",
      component: <All services={services} status={"ongoing"} />,
    },
    {
      id: 3,
      name: "Pending",
      component: <All services={services} status={"pending"} />,
    },
    {
      id: 4,
      name: "Completed",
      component: <All services={services} status={"completed"} />,
    },
    {
      id: 5,
      name: "Cancelled",
      component: <All services={services} status={"cancelled"} />,
    },
  ];

  const chartData = [
    { data: [23, 2], label: "23/25", description: "Services Completed" },
    { data: [214, 86], label: "$214", description: "Balance" },
  ];

  return (
    <Sidebar>
      <DashbboardHeader />
      <div className="min-h-screen pt-16 md:pt-28 pb-20 px-8">
        <div className="grid lg:grid-cols-5 gap-y-2 lg:gap-x-5 xl:gap-x-16">
          <section className="lg:col-span-3 lg:h-screen overflow-y-auto scrollbar-hide">
            <div>
              <h3 className="text-[#CCCCCC] text-sm">
                Welcome back{" "}
                <span className="font-bold text-xl text-[#353535] ml-1">
                  Contractor
                </span>
              </h3>
              <div className="py-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="font-semibold">Account Statement</h2>
                  <select
                    name="soa"
                    id="soa"
                    className="bg-transparent text-dark outline-none text-sm font-medium"
                  >
                    <option value="house1">Monthly</option>
                    <option value="house2">Yearly</option>
                  </select>
                </div>
                <div className="border border-gray rounded-2xl">
                  <DoughnutChart chartData={chartData} />
                </div>
              </div>
            </div>
            <section className="my-10">
              <p className="font-medium text-lg">Bookings</p>
              <div className="border border-[#105A5D1A] p-4 rounded-xl mt-3">
                <AppTabs tabs={tabs} />
              </div>
            </section>
          </section>
          <div>
            <div className="mt-3 w-fit">
              <Calender />
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export const All = ({ services, status }) => {
  return (
    <div className="my-6 space-y-8 scrollbar-hide">
      {status === "all"
        ? services.map((service) => <OrdersCard service={service} />)
        : services
            .filter((service) => service.status === status)
            .map((service) => <OrdersCard service={service} />)}
    </div>
  );
};

export default Contractor;
