import { GoDotFill } from "react-icons/go";
import AppButton from "../components/app-button/app-button.component";
import ActionCard from "../components/quick-actions-card/action-card";
import OrdersCard from "../components/orders-card/orders-card";
import Calender from "../components/calender/calender";
import AppTabs from "../components/tab/Tabs";
import Sidebar from "../components/sidebar/sidebar";
import DashbboardHeader from "../components/dashboard-header/dahboard-header";
import { useSelector } from "react-redux";
import { selectUser } from "../data/store/selectors/userSelector";
import { useNavigate } from "react-router-dom";
import { selectServices } from "../data/store/selectors/serviceSelector";
import moment from "moment";

const Tenant = () => {
  const user = useSelector(selectUser);
  const actionCardDetails = [
    {
      textHeader: "Request Service",
      text: "Easily submit a new service request for any property maintenance or improvement needs you have. Our network of trusted service providers siready to assist you with prompt and reliable service",
      link: "/post-a-request",
    },
    {
      textHeader: "Bookings",
      text: "Easily submit a new service request for any property maintenance or improvement needs you have. Our network of trusted service providers siready to assist you with prompt and reliable service",
      link: "/pending-requests",
    },
    // {
    //   textHeader: "Check your Inbox",
    //   text: "Easily submit a new service request for any property maintenance or improvement needs you have. Our network of trusted service providers siready to assist you with prompt and reliable service",
    //   link: "/inbox",
    // },
  ];
  const navigate = useNavigate();
  const services = useSelector(selectServices);
  const tabs = [
    {
      id: 1,
      name: "All",
      component: <All services={services} status={"all"} />,
    },
    {
      id: 2,
      name: "Ongoing",
      component: <All services={services} status={"ongoing"} />,
    },
    {
      id: 3,
      name: "Pending",
      component: <All services={services} status={"pending"} />,
    },
    {
      id: 4,
      name: "Completed",
      component: <All services={services} status={"completed"} />,
    },
    {
      id: 5,
      name: "Cancelled",
      component: <All services={services} status={"cancelled"} />,
    },
  ];

  return (
    <Sidebar>
      <DashbboardHeader />
      <div className="min-h-screen pt-16 md:pt-28 pb-6 px-8">
        <section>
          <div>
            <h3 className="text-[#CCCCCC] text-sm">
              Welcome back{" "}
              <span className="font-bold text-xl text-[#353535] ml-1">
                {user?.firstName}
              </span>
            </h3>
            <div className="grid lg:grid-cols-5 gap-y-6 md:gap-x-16 py-6">
              <div className="p-6 flex rounded-2xl text-sm border border-text bg-main text-[#fff] lg:col-span-3">
                <div className="flex flex-col space-y-5">
                  <div className="space-y-5">
                    <p className="text-[16px] font-medium">
                      Complete your profile
                    </p>
                    <p className="text-[#FFFFFFCC] text-[15px]">
                      Your profile is 25% complete. Complete your profile to
                      receive personalized service recommendations and special
                      offers
                    </p>
                  </div>
                  <div className="mt-auto">
                    <AppButton
                      buttonType={"outline"}
                      text={"Continue"}
                      onClick={() => navigate("/settings")}
                    />
                  </div>
                </div>
                <img
                  src="/tenant-dashboard.png"
                  alt=""
                  className="hidden md:block h-40 relative -right-16 -bottom-7"
                />
              </div>
              <div className="p-6 rounded-2xl text-sm border border-text flex-1 lg:col-span-2">
                <div className="flex items-center justify-between mb-4">
                  <p className="text-lg font-semibold">Lease details</p>
                  <div>
                    <select
                      name="house"
                      id="house"
                      className="bg-transparent text-text outline-none"
                    >
                      <option value="house1">House 1</option>
                      <option value="house2">House 2</option>
                    </select>
                  </div>
                </div>
                <div className="text-text font-medium text-[16px]">
                  <div className="flex justify-between items-center text-sm">
                    <div className="flex items-center text-sm">
                      <GoDotFill className="text-4xl text-lightGray" />
                      Check-in date
                    </div>
                    <p className="text-black">{moment(user?.leaseStartDate).format("D-MM-YYYY")}</p>
                  </div>
                  <div className="flex items-center justify-between text-sm">
                    <div className="flex items-center text-sm">
                      <GoDotFill className="text-4xl text-lightGray" />
                      Check-out Date
                    </div>
                    <p className="text-black">{moment(user?.leaseEndDate).format("D-MM-YYYY")}</p>
                  </div>
                  <div className="flex items-center justify-between text-sm">
                    <div className="flex items-center">
                      <GoDotFill className="text-4xl text-lightGray" />
                      Price
                    </div>
                    <div className="text-black">PHP {user?.leasePayment}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mt-6">
          <h2 className="font-medium">Quick Actions</h2>
          <div className="mt-4 flex gap-4 w-full overflow-x-auto scrollbar-hide">
            {actionCardDetails.map((actionCard, idx) => (
              <ActionCard actionCard={actionCard} key={idx} />
            ))}
          </div>
        </section>
        <section className="my-10">
          <div className="grid xl:grid-cols-5 gap-8">
            <div className="c col-span-3">
              <p className="font-medium text-lg">Orders</p>
              <div className="border border-[#105A5D1A] p-4 rounded-xl mt-3">
                <AppTabs tabs={tabs} />
              </div>
            </div>
            <div className="col-span-2">
              <p className="font-medium text-lg">Calender</p>
              <div className="mt-3 w-fit">
                <Calender />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Sidebar>
  );
};

export default Tenant;

const All = ({ services, status }) => {
  return (
    <div className="my-6 space-y-8 scrollbar-hide">
      {status === "all"
        ? services.map((service) => <OrdersCard service={service} />)
        : services
            .filter((service) => service.status === status)
            .map((service) => <OrdersCard service={service} />)}
    </div>
  );
};
