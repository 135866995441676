import { useNavigate } from "react-router-dom";
import TestimonialCard from "../components/testimonial-card/testimonial-card";
import List from "../components/list/list.component";
import AppButton from "../components/app-button/app-button.component";
import Footer from "../components/footer/footer";

const ContactUs = () => {
  const navigate = useNavigate();
  return (
    <>
      <header className="pt-32 pb-16 md:pb-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl text-center text-dark font-bold">
            Get In Touch
          </h2>
        </div>
      </header>
      <section className="pb-20" id="mission">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-8 md:gap-16">
            <img
              src={require("../assets/img/mission.jpg")}
              alt="maintenance service"
              className=""
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            />
            <div
              className="self-center"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              <h5 className="text-xl font-bold text-main border-b border-gray pb-3">
                Get in Touch with Us
              </h5>
              <p className="text-text mt-6 text-lg">
                We are here to help you with any questions, concerns or feedback
                you may have. Whether you need assistance with posting a
                service, connecting with a service provider or any other aspect
                of our platform, our team is ready to assist you. Your
                satisfaction is our priority and we strive to provide the best
                support possible. Feel free to reach out through any of the
                methods below, and we'll get back to you promptly.
              </p>
              {/* <div className="mt-6">
                <AppButton
                  buttonType={"primary"}
                  text={"Send us a message"}
                  width={"se"}
                  onClick={() => navigate("/services")}
                />  
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="pb-20" id="our-values">
        <div
          className="container mx-auto px-4"
          data-aos="zoom-in-right"
          data-aos-duration="2000"
        >
          <div className="grid md:grid-cols-2 gap-16">
            <div className="self-center order-2 md:order-1">
              <h5 className="text-xl font-bold text-main">
                Contact Information
              </h5>
              <p className="text-text">
                Reach out to us through any of the following methods
              </p>
              <ul className="mt-5 lg:mt-8 space-y-5 lg:space-y-10">
                <List
                  title={"Email"}
                  text={
                    <>
                      Send us an email at{" "}
                      <span className="text-[#3e3bf7] font-medium">
                        help@topspot.com
                      </span>{" "}
                      and we'll get back to you within 24 hours
                    </>
                  }
                />
                <List
                  title={"Phone"}
                  text={
                    <>
                      Call us at{" "}
                      <span className="text-[#3e3bf7] font-medium">
                        +123 456 7890
                      </span>{" "}
                      for immediate response
                    </>
                  }
                />
              </ul>
              <div className="mt-6">
                <AppButton
                  buttonType={"primary"}
                  text={"Explore our services"}
                  width={"se"}
                  onClick={() => navigate("/services")}
                />
              </div>
            </div>
            <img
              src={require("../assets/img/contact.png")}
              alt="renovation service"
              className="order-1 md:order-2"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            />
          </div>
        </div>
      </section>
      {/* <section className="pt-8">
        <div
          className="container mx-auto px-4"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          <div className="bg-main p-12 rounded-xl">
            <h1 className="text-xl md:text-3xl text-center font-bold text-white">
              Get a service today
            </h1>
            <p className="font-light text-lg text-white mt-4 text-center max-w-2xl mx-auto">
              Ready to request a service? Contact us today to discuss your
              property management needs and schedule an appointment with our
              experienced team
            </p>
            <div className="flex justify-center gap-8 mt-8">
              <AppButton
                buttonType={"white"}
                text={"Get A Quote"}
                onClick={() => navigate("/get-a-quote")}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-14">
        <div
          className="container mx-auto px-4"
          data-aos="zoom-in-right"
          data-aos-duration="2000"
        >
          <h3 className="text-main font-bold text-2xl mb-2">
            Word from our clients
          </h3>
          <div className="overflow-x-auto p-4 flex items-center gap-8 scrollbar-hide">
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
          </div>
        </div>
      </section> */}
      <Footer />
    </>
  );
};

export default ContactUs;
