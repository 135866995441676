import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  contractors: [],
  loading: false,
  error: null,
};

const ContractorSlice = createSlice({
  name: "contractor",
  initialState,
  reducers: {
    setContractors: (state, { payload }) => {
      state.contractors = payload;
    },
    updateContractors: (state, { payload }) => {
      state.contractors = updateContractorAccount(payload, state.contractors);
    },
    deleteOneContractor: (state, { payload }) => {
      state.contractors = removeContractor(payload, state.contractors);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContractors.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchContractors.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.contractors = payload;
    });
    builder.addCase(fetchContractors.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });
  },
});

export const { setContractors, updateContractors, deleteOneContractor } =
  ContractorSlice.actions;

export default ContractorSlice.reducer;

export const fetchContractors = createAsyncThunk(
  "contractors/fetchContractors",
  async (_, thunkAPI) => {
    // try {
    try {
      const res = await axios.get("/admin/all-contractors");
      console.log({ res });
      const contractors = await res.data.contractors;
      return contractors;
    } catch (error) {
      console.log({ fetchContractorsError: error });
      return thunkAPI.dispatch(error);
    }
  }
);

const updateContractorAccount = (contractor, contractors) => {
  // const foundContractor = contractors.find(
  //   (item) => item._id === contractor._id
  // );

  const newContractors = contractors.map((item) => {
    if (item._id === contractor._id) return contractor;
    return item;
  });
  return newContractors;
};

const removeContractor = (contractor, contractors) => {
  const foundContractor = contractors.filter(
    (item) => item._id === contractor._id
  );
  return foundContractor;
};
