import React from "react";
import { Rings } from 'react-loader-spinner'

const AppLoader = () => {
  return (
    <div className="fixed inset-0 z-20 flex items-center justify-center">
      <Rings
        visible={true}
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="rings-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};

export default AppLoader;
