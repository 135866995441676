import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/sidebar";
import { AdminCards } from "..";
import { useSelector } from "react-redux";
import {
  contractorLoading,
  selectContractors,
} from "../../data/store/selectors/contractorSelector";
import AppButton from "../../components/app-button/app-button.component";
import { toast } from "react-toastify";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const AssignContractor = () => {
  const navigate = useNavigate();
  const contractors = useSelector(selectContractors);
  const loading = useSelector(contractorLoading);
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState("");
  const service = useLocation().state;
  console.log({ service });
  const pageDetails = {
    header: "Contractor Assigned!",
    body: "You have successfully assigned a contractor to this task",
    buttonText: "Continue",
    navigateTo: "/",
  };

  const assignContractor = async () => {
    setIsLoading(true);
    try {
      const res = await axios.post(`/admin/assign-contractor/${service._id}`, {
        contractorId: active,
      });
      console.log({ res });
      navigate(`/pending-requests/approve-quote`, { state: pageDetails });
    } catch (error) {
      console.log({ error });
      toast.error(error.response?.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!service) navigate("/pending-requests");
  }, []);
  return (
    <Sidebar sidebarType={"reversed"}>
      <div className="container mx-auto px-4">
        <div className="max-w-2xl mx-auto p-8 mt-16">
          <h2 className="text-2xl font-semibold">Assign Contractor(s)</h2>
          <p className="text-md text-[#7D7D7D]">
            Select the best contractors for the job from the list of qualified
            professionals.{" "}
          </p>
          <div className="p-8 border border-[#105A5D1A] rounded-lg mt-4">
            {contractors.map((contractor) => (
              <AdminCards
                contractor={contractor}
                setActive={setActive}
                active={active}
              />
            ))}
          </div>
          <div className="flex mt-4">
            <AppButton
              buttonType={"pill"}
              text={"Continue"}
              width={"w-full"}
              loading={isLoading}
              onClick={assignContractor}
            />
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default AssignContractor;
