import { useState, useCallback, memo } from "react";
import { FaRegSquare } from "react-icons/fa";
import Footer from "../../components/footer/footer";
import AppButton from "../../components/app-button/app-button.component";
import { BiHeart } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/sidebar";

const images = [
  "/selected.png", // Main image (placeholder)
  "https://images.unsplash.com/photo-1717511140281-52586e5e307d", // Thumbnail 1 (placeholder)
  "https://images.unsplash.com/photo-1718294330460-7f6355d3895a", // Thumbnail 2 (placeholder)
  "https://images.unsplash.com/photo-1718657532611-e1fbcf73d776", // Thumbnail 3 (placeholder)
  "https://images.unsplash.com/photo-1718728593403-05c597938b62", // Thumbnail 4 (placeholder)
  "https://images.unsplash.com/photo-1718712760956-3e527953827b", // Thumbnail 5 (placeholder)
  "https://via.placeholder.com/300x200", // Thumbnail 6 (placeholder)
];

const ImageGallery = memo(() => {
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [expanded, setExpanded] = useState(false);
  const maxThumbnails = 5;
  const remainingImages = images.length - maxThumbnails;

  const handleImageClick = useCallback((image) => {
    setSelectedImage(image);
  }, []);

  return (
    <div className="w-full lg:max-w-5xl lg:mx-auto py-6 px-0">
      <div className="relative rounded-lg overflow-hidden shadow-lg mb-4">
        <img
          src={selectedImage}
          alt="Selected"
          className="w-full h-auto object-cover"
          loading="lazy"
        />
      </div>
      <div className="relative flex overflow-x-auto scrollbar-hide">
        <div
          className={`flex gap-4 overflow-hidden ${
            expanded && remainingImages && "flex-shrink-0"
          }`}
        >
          {images
            .slice(0, expanded ? images.length : maxThumbnails - 1)
            .map((image, index) => (
              <div
                key={index}
                onClick={() => handleImageClick(image)}
                className={`relative cursor-pointer rounded-lg overflow-hidden transition-transform transform hover:scale-105`}
              >
                <img
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  className="w-32 h-20 object-cover"
                  loading="lazy"
                />
              </div>
            ))}
          {!expanded && remainingImages > 0 && (
            <div
              onClick={() => {
                setExpanded(true);
                setSelectedImage(images[maxThumbnails]);
              }}
              className="relative cursor-pointer rounded-lg overflow-hidden transition-transform transform hover:scale-105"
            >
              <img
                src={images[maxThumbnails]}
                alt="More thumbnails"
                className="w-32 h-20 object-cover"
                loading="lazy"
              />
              <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center">
                <span className="text-dark text-xs font-semibold">
                  +{remainingImages} photos
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

const JobDetailsCard = memo(() => {
  const navigate = useNavigate();
  return (
    <div className="border border-lightGray w-full md:max-w-sm rounded-xl p-6">
      <div className="flex justify-between text-text text-sm mb-8 font-medium">
        <div className="space-y-6">
          <h2>Job details</h2>
          <div>
            <h4>Date Available</h4>
            <p className="text-black font-semibold">11 - 14/04/2024</p>
          </div>
          <p>Location</p>
          <p>Client budget</p>
        </div>
        <div className="flex flex-col items-end text-right space-y-6">
          <BiHeart className="text-2xl cursor-pointer" />
          <div>
            <h4>Time</h4>
            <p className="text-black font-semibold">10:00AM - 4:00PM</p>
          </div>
          <p className="text-black font-semibold">California, USA</p>
          <p className="text-black font-semibold">$3,000</p>
        </div>
      </div>
      <AppButton
        buttonType={"primary"}
        text={"Contact Tenant"}
        width={"full"}
        onClick={() => navigate("/find-requests/send-quote")}
      />
      <p className="text-sm text-text my-5">
        *Please provide a detailed quote for material costs
      </p>
      <div className="flex items-center justify-between border-t border-lightGray text-text pt-4 text-sm">
        <p className="font-medium">Client pays</p>
        <p className="text-black font-semibold">$3000</p>
      </div>
    </div>
  );
});

const FindRequestsParams = () => {
  return (
    <Sidebar sidebarType={"reversed"}>
      <div className="w-[90%] md:w-[94%] mx-auto grid md:grid-cols-5 gap-y-6 gap-x-8 xl:gap-x-16 my-6 mb-36">
        <div className="mx-auto md:col-span-3">
          <h1 className="font-semibold text-lg">
            Electrical Fittings in a 3-bedroom apartment
          </h1>
          <p className="text-sm mt-2">
            Posted by &bull;{" "}
            <span className="text-text underline">Heritage Jameson</span>
          </p>
          <ImageGallery />
          <h2 className="text-lg font-semibold mt-8 mb-3">Job Description</h2>
          <p className="text-[15px] text-dark">
            I am seeking an experienced electrician to perform electrical
            fitting work in 3-bedroom apartment. The scope of work includes
            installing electrical fixtures, outlets and switches, as well as
            ensuring compliance with safety standards regulations. I have also
            provided a blueprint of the apartment to assist with planning and
            execution.
          </p>
          <h2 className="text-lg font-semibold mt-8 mb-3">Scope of work</h2>
          <div className="space-y-3 text-dark">
            <p className="flex items-center gap-3">
              <span className="text-main">
                <FaRegSquare />
              </span>{" "}
              Install electrical fixtures (e.g lighting fixtures, ceiling fans)
            </p>
            <p className="flex items-center gap-3">
              <span className="text-main">
                <FaRegSquare />
              </span>{" "}
              Install electrical outlets and switches in designated locations.
            </p>
            <p className="flex items-center gap-3">
              <span className="text-main">
                <FaRegSquare />
              </span>{" "}
              Ensure proper wirings and connection according to safety
              standards.
            </p>
            <p className="flex items-center gap-3">
              <span className="text-main">
                <FaRegSquare />
              </span>{" "}
              Review the provided blueprint to understand the layout and
              electrical requirements to the apartment
            </p>
          </div>
        </div>
        <div className="md:col-span-2 py-10 w-full">
          <JobDetailsCard />
        </div>
      </div>
      <Footer />
    </Sidebar>
  );
};

export default FindRequestsParams;
