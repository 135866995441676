import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import Routers from "./Router";
import DataProvider from "./data/Context";
import $ from "jquery";
import { SetAuthToken, SetDefaultHeaders, TOKEN } from "./data/Config";
import store from "./data/store/store";
import { useEffect } from "react";
import { loadUser } from "./data/store/reducers/userSlice";
import AOS from "aos";
import "aos/dist/aos.css";
import "./App.css";

// Preloader
$(window).on("load", function () {
  $(".lds-ellipsis").fadeOut(); // will first fade out the loading animation
  $(".preloader").delay(333).fadeOut("slow"); // will fade out the white DIV that covers the website.
  $("body").delay(333);
});

SetDefaultHeaders();

if (localStorage.getItem(TOKEN)) {
  SetAuthToken(localStorage.getItem(TOKEN));
}

function App() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <>
      <Provider store={store}>
        <DataProvider>
          <Router>
            <Routers />
          </Router>
        </DataProvider>
      </Provider>
    </>
  );
}

export default App;
