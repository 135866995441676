import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/sidebar";
import Input from "../../components/input/input";
import AppButton from "../../components/app-button/app-button.component";

const SendQuote = () => {
  const navigate = useNavigate();

  return (
    <Sidebar sidebarType={"reversed"}>
      <section className="px-6 md:px-16 pt-4 pb-14 md:py-8">
        <h1 className="font-bold md:text-xl mb-6">
          Quote for Electrical Work - 3 Bedroom Apartment
        </h1>
        <div className="border border-lightGray rounded-xl md:max-w-[80%] p-4 md:p-6 space-y-5">
          <h2 className="font-medium text-lg">
            Service Type: Electrical Wiring and Fixture Installation
          </h2>
          <p className="text-sm text-text font-medium">
            Electrical fittings for a 3-bedroom apartment in Vancouver,
            California, USA
          </p>
        </div>
        <div className="border border-lightGray rounded-xl p-4 md:p-6 mt-12 space-y-10">
          <Input
            label={"Description of work"}
            type={"textArea"}
            placeholder={"Type in a minimum of 10 characters"}
          />
          <div>
            <Input
              label={"Estimated cost breakdown"}
              type={"textArea"}
              placeholder={"Type in a minimum of 10 characters"}
            />
            <p className="font-semibold mt-3 text-sm">
              Total Estimated Cost: $
            </p>
          </div>
        </div>
        <div className="border border-lightGray rounded-xl p-4 md:p-6 mt-12 space-y-5">
          <Input
            label={"Terms"}
            type={"textArea"}
            placeholder={"Type in a minimum of 10 characters"}
          />
          <AppButton
            buttonType={"primary"}
            text={"Send Quote"}
            onClick={() => navigate("/")}
          />
        </div>
      </section>
    </Sidebar>
  );
};

export default SendQuote;
