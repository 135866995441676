const InboxCard = ({ userDetails }) => {
  if (!userDetails) {
    return null;
  }

  const { displayImage, username, text, timeSent, numberOfMessagesSent } =
    userDetails;

  return (
    <>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-3">
          <img
            src={displayImage}
            alt="displayPic"
            className="h-[56px] w-[56px] rounded-full object-cover"
          />
          <div className="flex flex-col space-y-2">
            <h2 className="font-medium">
              {username.length > 20
                ? `${username.substring(0, 20)}...`
                : username}
            </h2>
            <p className="text-sm text-text">
              {text.length > 25 ? `${text.substring(0, 25)}...` : text}
            </p>
          </div>
        </div>
        <div className="flex flex-col space-y-3 items-end">
          <p className="text-xs text-text">{timeSent}</p>
          <span
            className={`bg-[#EB3232] text-white rounded-full w-6 h-6 text-sm flex items-center justify-center ${
              numberOfMessagesSent === 0 ? "invisible" : ""
            }`}
          >
            {numberOfMessagesSent}
          </span>
        </div>
      </div>
    </>
  );
};

const UserProfileCard = ({ selectedUser }) => {
  if (!selectedUser) {
    return null;
  }

  const { displayImage, username, lastSeen } = selectedUser;

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center gap-3">
        <img
          src={displayImage}
          alt="displayPic"
          className="max-h-[56px] max-w-[56px] rounded-full"
        />
        <div className="flex flex-col space-y-2">
          <h2 className="font-medium">
            {username.length > 20
              ? `${username.substring(0, 20)}...`
              : username}
          </h2>
          <p className="text-sm text-text">{`last seen ${lastSeen}`}</p>
        </div>
      </div>
    </div>
  );
};

export { InboxCard, UserProfileCard };
