import { CiSearch } from "react-icons/ci";
import { inboxSchema } from "../../chatData";
import { InboxCard } from "../profile-cards/profile-cards";

const ChatList = ({ selectedUser, handleCardClick }) => {
  return (
    <div
      className={`${
        selectedUser ? "hidden lg:flex" : "flex"
      } flex-col w-full lg:col-span-1 h-screen overflow-y-auto scrollbar-hide border-r border-lightGray`}
    >
      <div className="border-b border-lightGray min-h-[92px] max-h-[92px] flex flex-col justify-center items-start px-6">
        <h1 className="font-semibold text-xl text-black">Inbox</h1>
      </div>
      <div className="border-b border-lightGray min-h-[92px] max-h-[92px] flex flex-col justify-center items-start px-6 bg-[rgb(251,251,251)]">
        <div className="flex items-center gap-x-8 text-text text-sm font-medium">
          <CiSearch className="text-3xl" />
          <p>Search people, messages</p>
        </div>
      </div>
      {inboxSchema.map((userDetails) => (
        <div
          className="border-b border-lightGray min-h-[92px] max-h-[92px] flex px-6 cursor-pointer"
          key={userDetails.id}
          onClick={() => handleCardClick(userDetails)}
        >
          <div className="self-center w-full">
            <InboxCard userDetails={userDetails} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatList;
