import { BiArrowBack } from "react-icons/bi";
import { UserProfileCard } from "../profile-cards/profile-cards";
import Message, { ChatBubble } from "../message/message";

const ChatSection = ({
  selectedUser,
  messages,
  handleBackClick,
  messagesEndRef,
}) => {
  return (
    <div
      className={`${
        selectedUser ? "flex" : "hidden lg:flex"
      } flex-col w-full lg:col-span-3 h-screen overflow-y-auto scrollbar-hide`}
    >
      <div className="header border-b border-t border-lightGray min-h-[92px] max-h-[92px] flex justify-between items-center px-6 lg:px-14">
        {selectedUser ? (
          <div className="flex items-center gap-4">
            <button
              className="lg:hidden font-medium text-2xl my-2 cursor-pointer"
              onClick={handleBackClick}
            >
              <BiArrowBack />
            </button>
            <UserProfileCard selectedUser={selectedUser} />
          </div>
        ) : (
          <p className="text-lg font-medium">
            Select a message to view details
          </p>
        )}
      </div>
      <div className="flex-grow bg-[rgb(251,251,251)] min-h-screen pt-14 pb-5 px-6 lg:px-16 overflow-y-auto scrollbar-hide">
        <p className="w-full text-center border-b-2 border-[#9CA3B9] leading-[0.1em] mx-auto">
          <span className="bg-[rgb(251,251,251)] text-text font-medium px-[45px]">
            Today
          </span>
        </p>
        <div className="mt-14 mb-28">
          {messages.map((chat, index) => {
            const showAvatar =
              index === 0 || chat.sender !== messages[index - 1].sender;
            const showTime =
              index === messages.length - 1 ||
              chat.sender !== messages[index + 1].sender;
            const alignRight = chat.sender === "Darlene Robertson";
            const isLastInGroup =
              index === messages.length - 1 ||
              chat.sender !== messages[index + 1].sender;
            const marginBottom =
              showTime || index === messages.length - 1 ? "mb-6" : "";
            return (
              <div className={`${marginBottom}`} key={chat.id}>
                <Message
                  chat={chat}
                  properties={{
                    showAvatar,
                    alignRight,
                    showTime,
                    isLastInGroup,
                  }}
                >
                  <ChatBubble
                    chat={chat}
                    alignRight={alignRight}
                    showAvatar={showAvatar}
                  />
                </Message>
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </div>
      </div>
    </div>
  );
};

export default ChatSection;
