import React from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../app-button/app-button.component";

const Card = ({ img, title, content }) => {
  const navigate = useNavigate();

  return (
    <div className="card min-w-[24rem] md:max-w-[28rem border rounded-md border-gray p-4">
      <div className="md:h-[260px] overflow-hidden mb-2 rounded-md">
        <img
          src={require(`../../assets/img/${img}`)}
          alt=""
          className="object-cover object-center"
        />
      </div>
      <div>
        <h6 className="text-xl font-bold mb-2">{title || "Plumbing"}</h6>
        <p className="text-l text-text font-medium">
          {content ||
            "Swift and reliable plumbing solutions to keep your property running smoothly."}
        </p>
        <ul className="text-text text-sm space-y-2 mt-4 font-medium">
          <li className="l list-disc ml-5">
            Swift and reliable solutions for leaks, clogs, and installations
          </li>
          <li className="l list-disc ml-5">
            Repairs, maintenance, and upgrades for homes and businesses.
          </li>
        </ul>
        <div className="flex items-center justify-between mt-6">
          <div className="text-sm space-y-1 font-medium">
            {/* <p className="text-text">200 agents available</p>
            <p className="text-[16px]">From $1753</p> */}
          </div>
          <div>
            <AppButton
              buttonType={"primary"}
              text={"EXPLORE"}
              onClick={() => navigate("/services")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
