import { GoDot } from "react-icons/go";

const List = ({ title, text }) => {
  return (
    <li className="flex gap-2">
      <span className="text-2xl">
        <GoDot />
      </span>
      <div>
        <h6 className="font-bold text-dark text-lg">{title}</h6>
        <p className="text-lg text-text">{text}</p>
      </div>
    </li>
  );
};

export default List;
