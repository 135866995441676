import { Link, useLocation, useNavigate } from "react-router-dom";
import ScrollIntoView from "react-scroll-into-view";

import Brand from "../brand/brand";
import { ReactComponent as AppButton } from "../app-button/app-button.component";
import { ReactComponent as PlumbingIcon } from "../../assets/svg/plumbing.svg";
import { ReactComponent as ElectricalIcon } from "../../assets/svg/electrical.svg";
import { ReactComponent as PaintingIcon } from "../../assets/svg/painting.svg";
import { ReactComponent as CleaningIcon } from "../../assets/svg/cleaning.svg";
import { ReactComponent as FurnitureIcon } from "../../assets/svg/furniture.svg";
import { FiMail } from "react-icons/fi";
import { MdOutlinePhone } from "react-icons/md";
import { GrLocation } from "react-icons/gr";

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const services = [
    {
      id: 1,
      name: "Plumbing",
      subText: "Get a plumbing service",
      url: "#maintenance",
      icon: <PlumbingIcon />,
    },
    {
      id: 2,
      name: "Electrical work",
      subText: "Fix or install electrical appliances",
      url: "#maintenance",
      icon: <ElectricalIcon />,
    },
    {
      id: 3,
      name: "Painting",
      subText: "Beautify your space",
      url: "#renovation",
      icon: <PaintingIcon />,
    },
    {
      id: 4,
      name: "Room cleaning",
      subText: "Cleanup rooms with our experts",
      url: "#cleaning",
      icon: <CleaningIcon />,
    },
    {
      id: 5,
      name: "Furniture assembly",
      subText: "Assemble furniture the right way",
      url: "#renovation",
      icon: <FurnitureIcon />,
    },
  ];
  return (
    <div className="bg-main text-white">
      <div className="py-8 container mx-auto px-4">
        <div className="grid md:grid-cols-3 gap-16">
          <div>
            <Brand />
          </div>
          <div className="grid sm:grid-cols-2 md:grid-cols-2 gap-8 col-span-2 w-fit md:ml-auto">
            <div>
              <h4 className="font-semibold text-xl mb-4">Services</h4>
              <div>
                <ul className="space-y-2">
                  {services.map((service, idx) => (
                    <li key={idx} className="cursor-pointer">
                      <ScrollIntoView
                        selector={service.url}
                        onClick={() => {
                          location.pathname !== "/services" &&
                            navigate("/services");
                        }}
                      >
                        {service.name}
                      </ScrollIntoView>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* <div>
              <h4 className="font-semibold text-xl mb-4">About Us</h4>
              <div>
                <ul className="space-y-2">
                  <li>
                    <Link to={"/services"}>Plumbing</Link>
                  </li>
                  <li>
                    <Link to={"/services"}>Painting</Link>
                  </li>
                </ul>
              </div>
            </div> */}
            <div>
              <h4 className="font-semibold text-xl mb-4">
                Support & Resources
              </h4>
              <div>
                <ul className="space-y-2">
                  <li className="flex gap-2 items-center">
                    <div>
                      <FiMail />
                    </div>
                    <a href="mailto:hello@topspot.com">hello@topspot.com</a>
                  </li>
                  <li className="flex gap-2 items-center">
                    <div>
                      <MdOutlinePhone />
                    </div>
                    <a href="tel:(02) 97720525">(02) 97720525</a>
                  </li>
                  <li className="flex gap-2 items-center">
                    <div>
                      <MdOutlinePhone />
                    </div>
                    <a href="tel:09171221436">09171221436</a>
                  </li>
                  <li className="flex gap-2 items-center">
                    <div>
                      <GrLocation />
                    </div>
                    <p>
                      Unit 602 The Boni Tower, Boni Avenue Mandaluyong City,
                      Phillipines
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t-2 border-white py-4 text-center text-sm">
        Copyright TopSpot 2023
      </div>
    </div>
  );
};

export default Footer;
