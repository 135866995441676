import React from "react";
import Sidebar from "../../components/sidebar/sidebar";
import DashbboardHeader from "../../components/dashboard-header/dahboard-header";
import AppButton from "../../components/app-button/app-button.component";
import IconButtonMenu from "../../components/icon-menu/IconButtonMenu";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectTenant } from "../../data/store/selectors/tenantSelector";
import moment from "moment";

const Tenants = () => {
  const navigate = useNavigate();
  const tenants = useSelector(selectTenant).filter(
    (item) => item.userType === "tenant"
  );
  console.log({ tenants });
  return (
    <Sidebar>
      <DashbboardHeader />
      <div className="min-h-screen pt-16 md:pt-28 pb-20 px-8 bg-[#FAF9FF]">
        <div className="bg-white p-4">
          <h1 className="text-2xl font-semibold text-[#353535]">
            Current Tenants
          </h1>
          <p className="text-sm text-[#AEAEAE] max-w-md mt-2">
            Below is a list of all tenants currently in the system. You can view
            details, edit information, or remove tenants as needed
          </p>
          <div className="flex items-center justify-between mt-4">
            <p className="text-[#AEAEAE] text-lg">
              Total Tenants:{" "}
              <span className="text-black">{tenants?.length}</span>
            </p>
            <AppButton
              buttonType={"outlineMain"}
              text={"Add Tenant"}
              onClick={() => navigate("add-tenant")}
            />
          </div>

          <div className="relative overflow-x-auto mt-8">
            <table className="w-full text-sm text-left rtl:text-right">
              <thead className="">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Tenant Id
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Lease Start
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Lease End
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Location
                  </th>
                  {/* <th scope="col" className="px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {tenants.map((tenant) => (
                  <tr className="bg-white">
                    <th scope="row" className="px-6 py-4 whitespace-nowrap">
                      {tenant.tenantId}
                    </th>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {tenant.lastName} {tenant.firstName}
                    </td>
                    <td className="px-6 py-4">{tenant.email}</td>
                    <td className="px-6 py-4">
                      {moment(tenant.leaseStartDate).format("d/MM/YYYY")}
                    </td>
                    <td className="px-6 py-4">
                      {moment(tenant.leaseEndDate).format("d/MM/YYYY")}
                    </td>
                    <td className="px-6 py-4">{tenant.addressLine1}</td>
                    {/* <td className="px-6 py-4">
                      <IconButtonMenu contractorId={"12345"} />
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default Tenants;
