import { useRef, useState } from "react";
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  useTypeahead,
  offset,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
} from "@floating-ui/react";
import { FaSortDown } from "react-icons/fa";
import ScrollIntoView from "react-scroll-into-view";
import { useCycle } from "framer-motion";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";

import Brand from "../brand/brand";
import AppButton from "../app-button/app-button.component";
import { ReactComponent as PlumbingIcon } from "../../assets/svg/plumbing.svg";
import { ReactComponent as ElectricalIcon } from "../../assets/svg/electrical.svg";
import { ReactComponent as PaintingIcon } from "../../assets/svg/painting.svg";
import { ReactComponent as CleaningIcon } from "../../assets/svg/cleaning.svg";
import { ReactComponent as FurnitureIcon } from "../../assets/svg/furniture.svg";

const Nav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isUnboarding = () =>
    location.pathname === "/" || location.pathname.split("/")[1] === "/services"
      ? false
      : true;
  const [isOpen, toggleOpen] = useCycle(false, true);
  const routes = [
    { id: 1, name: "Home", url: "/" },
    {
      id: 2,
      name: "Services",
      url: "/services",
      subRoutes: [
        {
          id: 1,
          name: "Plumbing",
          subText: "Get a plumbing service",
          url: "#maintenance",
          icon: <PlumbingIcon />,
        },
        {
          id: 2,
          name: "Electrical work",
          subText: "Fix or install electrical appliances",
          url: "#maintenance",
          icon: <ElectricalIcon />,
        },
        {
          id: 3,
          name: "Painting",
          subText: "Beautify your space",
          url: "#renovation",
          icon: <PaintingIcon />,
        },
        {
          id: 4,
          name: "Room cleaning",
          subText: "Cleanup rooms with our experts",
          url: "#cleaning",
          icon: <CleaningIcon />,
        },
        {
          id: 5,
          name: "Furniture assembly",
          subText: "Assemble furniture the right way",
          url: "#renovation",
          icon: <FurnitureIcon />,
        },
      ],
    },
    // { id: 3, name: "Get a quote", url: "/get-a-quote" },
    { id: 3, name: "About Us", url: "/about-us" },
    { id: 3, name: "Contact Us", url: "/contact-us" },
  ];

  const { pathname, search } = location;
  const queryParams = new URLSearchParams(search);

  const showNav =
    !pathname.includes("register") &&
    !pathname.includes("login") &&
    !queryParams.has("onboard");

  const activePaths = ["/services", "/about-us", "/contact-us"];

  return (
    <div className="absolute bg-transparent inset-x-0 top-0 z-50">
      <nav className="flex items-center justify-between container mx-auto px-4 py-4">
        <div className="brand">
          <Brand />
        </div>
        {showNav && (
          <>
            <div
              className={`menu lg:flex items-center gap-20 hidden ${
                location.pathname === "/" ? "text-white" : "text-dark"
              }`}
            >
              {routes.map((route, idx) =>
                route.subRoutes ? (
                  <DropDown
                    key={idx}
                    options={route.subRoutes}
                    name={route.name}
                    url={route.url}
                  />
                ) : (
                  <NavLink
                    key={idx}
                    to={route.url}
                    className={({ isActive }) =>
                      isActive ? "font-bold" : "font-normal"
                    }
                  >
                    {route.name}
                  </NavLink>
                )
              )}
            </div>
            <div className="flex items-center gap-2 text-white">
              <div className="action lg:flex space-x-8 hidden">
                <AppButton
                  buttonType={`${
                    activePaths.includes(location.pathname)
                      ? "primary"
                      : "white"
                  }`}
                  text={"Sign Up"}
                  onClick={() => navigate("register")}
                />
                <AppButton
                  buttonType={`${
                    activePaths.includes(location.pathname)
                      ? "outlineMain"
                      : "outline"
                  }`}
                  text={"Login"}
                  onClick={() => navigate("login")}
                />
              </div>
              <button className="lg:hidden" onClick={() => toggleOpen()}>
                <GiHamburgerMenu
                  className={`${
                    location.pathname === "/services"
                      ? "text-black"
                      : "text-white"
                  } text-3xl`}
                />
              </button>
            </div>
          </>
        )}
      </nav>
      {isOpen && (
        <div
          className="fixed inset-0  bg-black bg-opacity-50 lg:opacity-0 transition-opacity duration-300"
          onClick={() => toggleOpen()}
        ></div>
      )}

      {/* mobile nav */}
      <div
        className={`fixed top-0 left-0 z-50 w-64 h-screen bg-white shadow-xl transition-transform transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="p-4 space-y-6">
          <Brand />
          <ul
            className={`menu flex gap-4 flex-col text-dark"
              }`}
          >
            {routes.map((route, idx) =>
              route.subRoutes ? (
                <MenuDD
                  route={route.name}
                  subRoutes={route.subRoutes}
                  toggleOpen={toggleOpen}
                />
              ) : (
                <li key={idx}>
                  <NavLink
                    to={route.url}
                    className={({ isActive }) =>
                      isActive ? "font-bold" : "font-normal"
                    }
                    onClick={toggleOpen}
                  >
                    {route.name}
                  </NavLink>
                </li>
              )
            )}
          </ul>
          <div className="action flex flex-col space-y-2 lg:hidden">
            <AppButton
              buttonType={"outlineBlack"}
              text={"Sign up"}
              onClick={() => {
                navigate("register");
                toggleOpen();
              }}
            />
            <AppButton
              buttonType={"primary"}
              text={"Login"}
              onClick={() => {
                navigate("login");
                toggleOpen();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;

export const DropDown = ({ options, name, url }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-middle",
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = useRef([]);
  const listContentRef = useRef(options);
  const isTypingRef = useRef(false);

  const click = useClick(context, { event: "mousedown" });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "listbox" });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    // This is a large list, allow looping.
    loop: true,
  });
  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex,
    onMatch: isOpen ? setActiveIndex : setSelectedIndex,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping;
    },
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [dismiss, role, listNav, typeahead, click]
  );

  const handleSelect = (index) => {
    setSelectedIndex(index);
    setIsOpen(false);
  };

  //   const selectedItemLabel =
  //     selectedIndex !== null ? options[selectedIndex] : undefined;

  return (
    <>
      {/* <label
        id="select-label"
        onClick={() => refs.domReference.current?.focus()}
      >
        Select balloon color
      </label> */}
      <button className="flex items-center gap-2">
        <NavLink
          to={url}
          className={({ isActive }) => (isActive ? "font-bold" : "font-normal")}
        >
          {name}
        </NavLink>
        <span
          tabIndex={0}
          ref={refs.setReference}
          aria-labelledby="select-label"
          aria-autocomplete="none"
          style={{ lineHeight: 2, margin: "auto" }}
          {...getReferenceProps()}
        >
          {/* {selectedItemLabel || "Select..."} */}
          <FaSortDown />
        </span>
      </button>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
                minWidth: 100,
                outline: 0,
                zIndex: 50,
              }}
              {...getFloatingProps()}
              className="grid grid-cols-2 bg-white rounded-2xl p-2"
            >
              {options.map((value, i) => (
                <div
                  key={value?.id}
                  ref={(node) => {
                    listRef.current[i] = node;
                  }}
                  role="option"
                  tabIndex={i === activeIndex ? 0 : -1}
                  aria-selected={i === selectedIndex && i === activeIndex}
                  style={{
                    padding: 10,
                    cursor: "pointer",
                    background: i === activeIndex ? "cyan" : "",
                  }}
                  {...getItemProps({
                    // Handle pointer select.
                    onClick() {
                      handleSelect(i);
                    },
                    // Handle keyboard select.
                    onKeyDown(event) {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        handleSelect(i);
                      }

                      if (event.key === " " && !isTypingRef.current) {
                        event.preventDefault();
                        handleSelect(i);
                      }
                    },
                  })}
                  className="flex items-center gap-2 border border-transparent hover:border-black"
                >
                  <ScrollIntoView
                    selector={value.url}
                    onClick={() => {
                      location.pathname !== "/services" &&
                        navigate("/services");
                    }}
                  >
                    <span>{value?.icon}</span>
                    <div className="text-xs text-main">
                      <p className="font-bold">{value?.name}</p>
                      <p>{value?.subText}</p>
                    </div>
                    {/* <span
                    aria-hidden
                    style={{
                      position: "absolute",
                      right: 10,
                    }}
                  >
                    {i === selectedIndex ? " ✓" : ""}
                  </span> */}
                  </ScrollIntoView>
                </div>
              ))}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};

const MenuDD = ({ subRoutes, route, toggleOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Dropdown>
      <MenuButton
        sx={{
          padding: 0,
          border: "none",
          width: "fit-content",
          ":hover": { backgroundColor: "transparent" },
        }}
      >
        {route}
      </MenuButton>
      <Menu>
        {subRoutes.map((route) => (
          <ScrollIntoView
            selector={route.url}
            onClick={() => {
              location.pathname !== "/services" && navigate("/services");
              toggleOpen();
            }}
          >
            <MenuItem>{route.name}</MenuItem>
          </ScrollIntoView>
        ))}
      </Menu>
    </Dropdown>
  );
};
