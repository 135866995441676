import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  services: [],
  allServices: [],
  loading: false,
  error: null,
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setService: (state, { payload }) => {
      state.services.push(payload);
    },
    removeService: (state, { payload }) => {
      state.services = cancelService(payload, state.services);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServices.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchServices.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.services = payload;
    });
    builder.addCase(fetchServices.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.response?.data?.error;
    });
    builder.addCase(fetchAllServices.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.response?.data?.error;
    });
    builder.addCase(fetchAllServices.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllServices.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.services = payload;
    });
    builder.addCase(fetchContractorServices.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchContractorServices.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.services = payload;
    });
    builder.addCase(fetchContractorServices.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.response?.data?.error;
    });
  },
});

export const { setService, removeService } = serviceSlice.actions;
export default serviceSlice.reducer;

export const fetchServices = createAsyncThunk(
  "service/fetchServices",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("/service/user-services");
      console.log({ res });
      const services = await res.data.services;
      return services;
    } catch (error) {
      console.log({ fetchServicesError: error });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchAllServices = createAsyncThunk(
  "service/fetchAllServices",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("/admin/get-all-services");
      console.log({ res });
      const services = await res.data.services;
      return services;
    } catch (error) {
      console.log({ fetchAllServices: error });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchContractorServices = createAsyncThunk(
  "service/fetchContractorServices",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("/contractor/all-contractor-services");
      console.log({ res });
      const services = await res.data.services;
      return services;
    } catch (error) {
      console.log({ fetchContractorServices: error });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const cancelService = (service, services) => {
  const foundService = services.filter((item) => item._id === service._id);
  return foundService;
};
