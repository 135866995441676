import { useNavigate } from "react-router-dom";
import { ReactComponent as PlumbingIcon } from "../assets/svg/plumbing.svg";
import List from "../components/list/list.component";
import AppButton from "../components/app-button/app-button.component";
import Footer from "../components/footer/footer";

console.log("inServices");
const Services = () => {
  const navigate = useNavigate();
  return (
    <>
      <header className="pt-32 pb-16 md:pb-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl text-center text-dark font-bold">
            Explore Our Services
          </h2>
        </div>
      </header>
      <section className="pb-20" id="maintenance">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-8 md:gap-16">
            <img
              src={require("../assets/img/maintenance-service.png")}
              alt="maintenance service"
              className="w-full"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            />
            <div
              className="self-center"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              <h5 className="text-xl font-bold text-main flex items-center gap-2">
                {" "}
                <span>
                  <PlumbingIcon />
                </span>
                Maintenance Services
              </h5>
              <ul className="mt-5 lg:mt-8 space-y-5 lg:space-y-10">
                <List
                  title={"Electrical Work"}
                  text={
                    "Ensure the safety and functionality of your property's electrical systems with our professional wiring, fixture installation, and repair services."
                  }
                />
                <List
                  title={"Plumbing Services"}
                  text={
                    "From leak repairs to pipe installations, we handle all plumbing needs to keep your property's water systems running smoothly."
                  }
                />
              </ul>
              <div className="mt-6">
                <AppButton
                  buttonType={"primary"}
                  text={"get a Quote"}
                  onClick={() => navigate("/get-a-quote")}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-20" id="renovation">
        <div
          className="container mx-auto px-4"
          data-aos="zoom-in-right"
          data-aos-duration="2000"
        >
          <div className="grid md:grid-cols-2 gap-16">
            <div className="self-center order-2 md:order-1">
              <h5 className="text-xl font-bold text-main flex items-center gap-2">
                {" "}
                <span>
                  <PlumbingIcon />
                </span>
                Renovation Services
              </h5>
              <ul className="mt-5 lg:mt-8 space-y-5 lg:space-y-10">
                <List
                  title={"Painting"}
                  text={
                    "Refresh and revitalize your property's interior with our professional painting services, including color consultations and high-quality finishes."
                  }
                />
                <List
                  title={"Furniture Assembly"}
                  text={
                    "Let us handle your furniture assembly hassle. Our expert team ensures safety and precision, sparing you from confusion. Relax as we turn your furniture dreams into reality"
                  }
                />
              </ul>
              <div className="mt-6">
                <AppButton
                  buttonType={"primary"}
                  text={"get a Quote"}
                  onClick={() => navigate("/get-a-quote")}
                />
              </div>
            </div>
            <img
              src={require("../assets/img/renovation-service.png")}
              alt="renovation service"
              className="w-full order-1 md:order-2"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            />
          </div>
        </div>
      </section>
      <section className="pb-14" id="cleaning">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-16">
            <img
              src={require("../assets/img/cleaning-service.png")}
              alt="cleaning service"
              className="w-full"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            />
            <div
              className="self-center"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              <h5 className="text-xl font-bold text-main flex items-center gap-2">
                {" "}
                <span>
                  <PlumbingIcon />
                </span>
                Cleaning Services
              </h5>
              <ul className="mt-5 lg:mt-8 space-y-5 lg:space-y-10">
                <List
                  title={"Room Cleaning"}
                  text={
                    "Refresh your space with our meticulous room cleaning service. Our dedicated team ensures every corner is sparkling clean, leaving your rooms pristine and inviting. Say goodbye to dust, dirt, and clutter, and hello to a fresh and rejuvenated living environment. Let us transform your space into a haven of cleanliness and comfort"
                  }
                />
              </ul>
              <div className="mt-6">
                <AppButton
                  buttonType={"primary"}
                  text={"get a Quote"}
                  onClick={() => navigate("/get-a-quote")}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-8">
        <div
          className="container mx-auto px-4 mb-20"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          <div className="bg-main p-12 rounded-xl">
            <h1 className="text-xl md:text-3xl text-center font-bold text-white">
              Get a service today
            </h1>
            <p className="font-light text-lg text-white mt-4 text-center max-w-2xl mx-auto">
              Ready to request a service? Contact us today to discuss your
              property management needs and schedule an appointment with our
              experienced team
            </p>
            <div className="flex justify-center gap-8 mt-8">
              <AppButton
                buttonType={"white"}
                text={"Get A Quote"}
                onClick={() => navigate("/get-a-quote")}
              />
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default Services;
