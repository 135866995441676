import { useDispatch, useSelector } from "react-redux";
import displayPic from "../assets/img/cleaning-service.png";
import AppButton from "../components/app-button/app-button.component";
import Input from "../components/input/input";
import Sidebar from "../components/sidebar/sidebar";
import { selectUser } from "../data/store/selectors/userSelector";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import AppSettings from "../components/settings/Settings";

const Settings = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState();
  console.log({ user });
  const defaultData = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    username: user?.username || "",
    email: user?.email || "",
    recoveryEmail: user?.recoveryEmail || "",
    currentPassword: user?.currentPassword || "",
    newPassword: user?.newPassword || "",
    country: user?.country || "",
    state: user?.state || "",
    city: user?.city || "",
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await axios.put("/update", formData);
      console.log({ res });
      dispatch(res.data.user);
      toast.success(res.data.msg);
    } catch (error) {
      console.log({ error });
      toast.error(error.response?.data?.error);
    }
    setLoading(false);
  };

  const [formData, setFormData] = useState(defaultData);

  return <AppSettings />;
};

export default Settings;
