import { useState, useRef, useEffect } from "react";
import { chatData } from "../chatData";
import ChatList from "../components/chat-list/chat-list";
import ChatSection from "../components/chat-section/chat-section";
import MessageInput from "../components/message-input/message-input";
import Sidebar from "../components/sidebar/sidebar";

const Inbox = () => {
  const messagesEndRef = useRef(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState(chatData);
  const [inputValue, setInputValue] = useState("");

  const handleCardClick = (userDetails) => {
    setSelectedUser(userDetails);
  };

  const handleBackClick = () => {
    setSelectedUser(null);
  };

  const handleSendMessage = () => {
    if (!inputValue.trim()) return;

    const newMessageData = {
      id: messages.length + 1,
      sender: "Darlene Robertson",
      timeSent: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      fileType: "text",
      message: inputValue,
      displayPic: "../display-picture.jpg",
    };
    setMessages([...messages, newMessageData]);
    setInputValue("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, selectedUser]);

  return (
    <Sidebar sidebarType={"reversed"}>
      <div className="min-h-screen">
        <section className="min-h-screen flex">
          <div className="grid lg:grid-cols-4 flex-grow">
            <ChatList
              selectedUser={selectedUser}
              handleCardClick={handleCardClick}
            />
            <ChatSection
              selectedUser={selectedUser}
              messages={messages}
              handleBackClick={handleBackClick}
              messagesEndRef={messagesEndRef}
            />
            {(selectedUser || window.innerWidth >= 1024) && (
              <MessageInput
                inputValue={inputValue}
                handleKeyDown={handleKeyDown}
                setInputValue={setInputValue}
              />
            )}
          </div>
        </section>
      </div>
    </Sidebar>
  );
};

export default Inbox;
