import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/sidebar";
import { Controller, useForm } from "react-hook-form";
import Input from "../../components/input/input";
import AppButton from "../../components/app-button/app-button.component";
import {
  GetCountries,
  GetState,
  GetCity,
  GetLanguages, //async functions
} from "react-country-state-city";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setTenant } from "../../data/store/reducers/tenantSlice";

const AddTenant = () => {
  const [stage, setStage] = useState("tenant");
  const location = useLocation();

  const step = location.search.split("=")[1];
  console.log({ stage });
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      country: "",
      email: "",
      postalCode: "",
      contactNumber: "",
      lodgeName: "",
      tenantRoomNumber: "",
      secondaryContactNumber: "",
      addressLine1: "",
      addressLine2: "",
      leaseStartDate: "",
      leaseEndDate: "",
      leasePayment: "",
    },
  });

  return (
    <Sidebar sidebarType={"reversed"}>
      <div className="py-8">
        {step ? (
          <Success />
        ) : (
          <HandleAddTenant
            stage={stage}
            setStage={setStage}
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
    </Sidebar>
  );
};

export default AddTenant;

const HandleAddTenant = ({
  stage,
  setStage,
  handleSubmit,
  control,
  errors,
}) => {
  switch (stage) {
    case "tenant":
      return (
        <AddNewTenant
          setStage={setStage}
          control={control}
          errors={errors}
          handleSubmit={handleSubmit}
        />
      );
    case "location":
      return (
        <AddTenantLocation
          setStage={setStage}
          control={control}
          errors={errors}
          handleSubmit={handleSubmit}
        />
      );
    default:
      break;
  }
};

const AddNewTenant = ({ setStage, control, errors, handleSubmit }) => {
  const onSubmit = (data) => {
    console.log({ data });
    setStage("location");
  };

  return (
    <form
      className="max-w-md mx-auto px-4 md:px-0"
      onSubmit={handleSubmit(onSubmit)}
    >
      <article className="mb-4">
        <h1 className="text-2xl font-semibold">Add a new Tenant</h1>
        <p className="text-text font-medium mt-2">
          Enter tenant first name and last name. Confirm the details of the
          tenant before filling this
        </p>
      </article>
      <div className="grid sm:grid-cols-2 gap-4">
        <div className="col-span-2">
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"First name"}
                placeholder="John"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.firstName && (
            <p className="text-red text-xs">{errors.firstName.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Last name"}
                placeholder="Doe"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.lastName && (
            <p className="text-red text-xs">{errors.lastName.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="lodgeName"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Lodge name"}
                placeholder="Ray Lodge"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.lodgeName && (
            <p className="text-red text-xs">{errors.lodgeName.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="tenantRoomNumber"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Room number"}
                placeholder="2b"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.tenantRoomNumber && (
            <p className="text-red text-xs">
              {errors.tenantRoomNumber.message}
            </p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid email format",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Enter you email"}
                placeholder="Email"
                type={"email"}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.email && (
            <p className="text-red text-xs">This field is required.</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="contactNumber"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Phone Number"}
                value={value}
                onChange={onChange}
                type={"number"}
              />
            )}
          />
          {errors.contactNumber && (
            <p className="text-red text-xs">{errors.contactNumber.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="secondaryContactNumber"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Secondary Phone Number"}
                type={"number"}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.secondaryContactNumber && (
            <p className="text-red text-xs">
              {errors.secondaryContactNumber.message}
            </p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="leasePayment"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Lease amount"}
                value={value}
                onChange={onChange}
                type={"number"}
              />
            )}
          />
          {errors.leasePayment && (
            <p className="text-red text-xs">{errors.leasePayment.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="leaseStartDate"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Lease Start Date"}
                value={value}
                onChange={onChange}
                type={"date"}
              />
            )}
          />
          {errors.leaseStartDate && (
            <p className="text-red text-xs">{errors.leaseStartDate.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="leaseEndDate"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Lease End Date"}
                value={value}
                type={"date"}
                onChange={onChange}
              />
            )}
          />
          {errors.leaseEndDate && (
            <p className="text-red text-xs">{errors.leaseEndDate.message}</p>
          )}
        </div>
      </div>
      <div className="mt-4">
        <AppButton text={"Continue"} buttonType={"pill"} width={"full"} />
      </div>
    </form>
  );
};

const AddTenantLocation = ({ setStage, control, errors, handleSubmit }) => {
  const dispatch = useDispatch();
  const [countryId, setCountryId] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);

  const onChange = async (data) => {
    console.log({ data });
    setLoading(true);
    try {
      const res = await axios.post("/admin/create-tenant", data);
      console.log({ res });
      toast.success("Tenant created successfully");
      const tenant = await res.data.user;
      dispatch(setTenant(tenant));
      navigate("?status=complete");
    } catch (error) {
      console.log({ error });
      toast.error(error.response?.data?.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);
  useEffect(() => {
    GetState(countryId).then((result) => {
      setStateList(result);
    });
  }, [countryId]);

  return (
    <form
      className="max-w-md mx-auto px-4 md:px-0"
      onClick={handleSubmit(onChange)}
    >
      <article className="mb-4">
        <h1 className="text-2xl font-semibold">Set your Location</h1>
        <p className="text-text font-medium mt-2">
          Help us find the best services near you by setting your location
          belows
        </p>
      </article>
      <div className="grid sm:grid-cols-2 gap-4">
        <div className="col-span-2">
          <Controller
            name="country"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Country"}
                type={"select"}
                selectHolder="select country"
                options={
                  countriesList.map((country) => country?.name) || [
                    "Loading...",
                  ]
                }
                value={value}
                onChange={(e) => {
                  onChange(e);
                  const newVal = e.target.value;
                  let selectedValue = countriesList.filter(
                    (country) => country.name === newVal
                  );
                  console.log({ selectedValue });
                  setCountryId(selectedValue[0]?.id);
                }}
              />
            )}
          />
          {errors.country && (
            <p className="text-red text-xs">{errors.country.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="state"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"State/Region"}
                type={"select"}
                selectHolder="select region"
                options={
                  stateList.map((country) => country?.name) || ["Loading..."]
                }
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.state && (
            <p className="text-red text-xs">{errors.state.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="addressLine1"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Address Line 1"}
                placeholder="Doe"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.addressLine1 && (
            <p className="text-red text-xs">{errors.addressLine1.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="addressLine2"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Address Line 2"}
                placeholder="Doe"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.addressLine2 && (
            <p className="text-red text-xs">{errors.addressLine2.message}</p>
          )}
        </div>
      </div>
      <div className="mt-4">
        <AppButton
          text={"Continue"}
          buttonType={"pill"}
          width={"full"}
          loading={loading}
        />
      </div>
    </form>
  );
};

const Success = () => {
  const navigate = useNavigate();

  const onSubmit = async () => {
    navigate("/tenants");
  };

  return (
    <div className="w-[90%] md:max-w-md mx-auto mt-16">
      <article className="mb-4 text-center space-y-6">
        <h1 className="text-2xl font-semibold">Complete</h1>
        <p className="text-text">
          Your account has been created. Follow the button below to access your
          dashboard and post a service you need as soon as possible
        </p>
      </article>
      <div className="mt-6">
        <AppButton
          text={"Proceed"}
          buttonType={"pill"}
          width={"full"}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};
