import { createSelector } from "@reduxjs/toolkit";

export const serviceSelector = (state) => state?.service;

export const selectServices = createSelector(
  [serviceSelector],
  (services) => services?.services
);

export const selectAllServices = createSelector(
  [serviceSelector],
  (services) => services?.allServices
);

export const serviceLoading = createSelector(
  [serviceSelector],
  (loading) => loading?.loading
);

export const serviceError = createSelector(
  [serviceSelector],
  (error) => error?.error
);
