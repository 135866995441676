import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalState } from "../../data/Context";
import { CiSearch } from "react-icons/ci";
import { RiNotification3Line } from "react-icons/ri";
import displayPicture from "../../assets/img/display-picture.jpg";
import { useSelector } from "react-redux";
import { selectUser } from "../../data/store/selectors/userSelector";

const DashbboardHeader = () => {
  const navigate = useNavigate();
  const { toggleNav } = useContext(GlobalState);
  const user = useSelector(selectUser);

  return (
    <header className="fixed top-0 left-0 w-full py-4 px-6 shadow-md bg-white z-10 md:pl-64 md:pr-12">
      <div className="flex flex-1 justify-between items-center">
        <div className="relative md:mx-auto w-2/5">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
            <CiSearch className="text-xl text-gray-600" />
          </div>
          <input
            type="search"
            name="search"
            id="search"
            placeholder="search for tasks"
            className="shrink w-full pl-10 pr-4 py-2 rounded-3xl border border-text focus:outline-none text-gray-600"
          />
        </div>
        <div className="flex gap-x-4 md:gap-x-8 items-center">
          <RiNotification3Line
            className="text-2xl text-text cursor-pointer"
            onClick={() => navigate("/inbox")}
          />
          <div
            className="items-center gap-2 cursor-pointer hidden md:flex"
            onClick={() => navigate("/settings")}
          >
            <img
              src={user?.avatar || displayPicture}
              alt="display-picture"
              className="rounded w-[51px] h-[50px] object-cover"
            />
            <span className="font-medium hidden md:block">
              {user?.firstName} {user?.lastName}
            </span>
          </div>
          <button
            type="button"
            className="md:hidden inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            onClick={toggleNav}
          >
            <span className="sr-only">Open sidebar</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </header>
  );
};

export default DashbboardHeader;
