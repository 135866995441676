import { useSelector } from "react-redux";
import Footer from "../components/footer/footer";
import Quotes, { Services } from "../components/quotes/quotes";
import Sidebar from "../components/sidebar/sidebar";
import {
  selectServices,
  serviceLoading,
} from "../data/store/selectors/serviceSelector";

const PendingRequests = () => {
  const quotes = useSelector(selectServices);
  console.log({ quotes });
  const loading = useSelector(serviceLoading);
  return (
    <Sidebar sidebarType={"reversed"}>
      <section className="max-w-[90%] lg:max-w-[66rem] mx-auto mt-6 mb-10 lg:mt-10 lg:mb-16">
        {quotes && <Services services={quotes} loading={loading} />}
      </section>
      <footer>
        <Footer />
      </footer>
    </Sidebar>
  );
};

export default PendingRequests;
