import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";

import AppButton from "../../components/app-button/app-button.component";
// import { ReactComponent as Google } from "../../assets/svg/google.svg";
// import Input, { Checkbox } from "../../components/input/input";
import ChooseRole from "../../components/choose-role/choose-role";
import { useDispatch } from "react-redux";
import { login } from "../../data/store/reducers/userSlice";
import axios from "axios";
import Input from "../../components/input/input";
import { GlobalState } from "../../data/Context";

const Onboarding = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stage = location.search.split("=")[1];

  useEffect(() => {
    if (!location.search) {
      navigate(`/register/onboarding?stage=1`);
    }
    console.log({ stage });
  }, [location.search, navigate, stage]);

  return (
    <div className="min-h-screen flex items-center justify-center flex-col gap-4">
    </div>
  );
};

export default Onboarding;

const MeetYou = () => {
  const location = useLocation();
  const reg = location.state;
  console.log({ reg });
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
    },
  });

  const onSubmit = async (data) => {
    console.log({ data });
    navigate(`/register/onboarding?stage=${2}`, { state: { ...reg, ...data } });
  };
  return (
    <form
      className="max-w-md mx-auto px-4 md:px-0"
      onSubmit={handleSubmit(onSubmit)}
    >
      <article className="mb-4">
        <h1 className="text-2xl font-semibold">Let's meet you</h1>
        <p className="text-text font-medium mt-2">
          Enter your full name. This will be displayed publicly on your profile
        </p>
      </article>
      <div className="grid sm:grid-cols-2 gap-4">
        <div className="col-span-2">
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"First name"}
                placeholder="John"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.firstName && (
            <p className="text-red text-xs">{errors.firstName.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: "This field is required",
              minLength: {
                value: 3,
                message: "name must be a minimum of 3 characters",
              },
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Last name"}
                placeholder="Doe"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.lastName && (
            <p className="text-red text-xs">{errors.lastName.message}</p>
          )}
        </div>
      </div>
      <div className="mt-4">
        <AppButton text={"Continue"} buttonType={"pill"} width={"full"} />
      </div>
    </form>
  );
};