import { useDispatch, useSelector } from "react-redux";
import displayPic from "../../assets/img/cleaning-service.png";
import AppButton from "../app-button/app-button.component";
import Input from "../input/input";
import Sidebar from "../sidebar/sidebar";
import { selectUser } from "../../data/store/selectors/userSelector";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { setUser } from "../../data/store/reducers/userSlice";

const AppSettings = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState();
  console.log({ user });
  const defaultData = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    recoveryEmail: user?.recoveryEmail || "",
    currentPassword: user?.currentPassword || "",
    newPassword: user?.newPassword || "",
    country: user?.country || "",
    state: user?.state || "",
    city: user?.city || "",
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await axios.put("/update", formData);
      console.log({ res });
      dispatch(setUser(res.data.user));
      toast.success("user updated");
    } catch (error) {
      console.log({ error });
      toast.error(error.response?.data?.error);
    }
    setLoading(false);
  };

  const [formData, setFormData] = useState(defaultData);

  return (
    <Sidebar>
      <div className="w-[80%] xl:max-w-4xl mx-auto py-14">
        <h1 className="text-3xl font-semibold text-dark">Bio & Profile</h1>
        <p className="text-lightGray text-[15px] font-medium mt-2">
          Edit your user profile
        </p>
        <h3 className="text-lg text-dark font-medium mt-5 mb-3">
          Profile Picture
        </h3>
        <UpdateProfilePicture />
        <div className="grid grid-cols-4 gap-x-4 md:gap-x-8 gap-y-10 my-12 lg:max-w-2xl">
          <div className="col-span-2">
            <Input
              label={"First Name"}
              type={"text"}
              placeholder={"Heritage"}
              name={"firstName"}
              value={formData.firstName}
              onChange={handleInput}
            />
          </div>
          <div className="col-span-2">
            <Input
              label={"Last Name"}
              type={"text"}
              placeholder={"Atiba"}
              name={"lastName"}
              value={formData.lastName}
              onChange={handleInput}
            />
          </div>
          <div className="col-span-2">
            <Input
              label={"Email"}
              type={"email"}
              placeholder={"atibaheritage@gmail.com"}
              name={"email"}
              value={formData.email}
              onChange={handleInput}
            />
          </div>
        </div>
        <div className="border-t border-gray"></div>
        <div className="py-12 lg:max-w-2xl">
          <h2 className="text-2xl font-semibold text-dark">Account Settings</h2>
          <p className="text-lightGray text-[15px] font-medium mt-2">
            Check and edit your account settings
          </p>
          <div className="mt-8 space-y-6">
            <Input
              label={"Recovery Email"}
              type={"email"}
              placeholder={"ethan@gmail.com"}
              name={"recoveryEmail"}
              value={formData.recoveryEmail}
              onChange={handleInput}
            />
            <Input
              label={"Current Password"}
              type={"password"}
              placeholder={"******"}
              name={"currentPassword"}
              value={formData.currentPassword}
              onChange={handleInput}
            />
            <Input
              label={"New Password"}
              type={"password"}
              placeholder={"******"}
              name={"newPassword"}
              value={formData.newPassword}
              onChange={handleInput}
            />
          </div>
        </div>
        <div className="py-12 border-t border-gray">
          <h2 className="text-2xl font-semibold text-dark">Location</h2>
          <p className="text-lightGray text-[15px] font-medium mt-2">
            Check and edit your account settings
          </p>
          <div className="flex gap-3 md:gap-6 items-center my-8">
            <div className="flex-grow">
              <Input
                label={"Country"}
                type={"text"}
                placeholder={"Nigeria"}
                name={"country"}
                value={formData.country}
                onChange={handleInput}
              />
            </div>
            <div className="flex-grow">
              <Input
                label={"State"}
                type={"text"}
                placeholder={"Oyo"}
                name={"state"}
                value={formData.state}
                onChange={handleInput}
              />
            </div>
            <div className="flex-grow">
              <Input
                label={"City"}
                type={"text"}
                placeholder={"Ibadan"}
                name={"city"}
                value={formData.city}
                onChange={handleInput}
              />
            </div>
          </div>
          <div className="text-right flex justify-end gap-2">
            <AppButton
              buttonType={"outlineBlack"}
              text={"Cancel"}
              width={"norms"}
              onClick={() => {
                console.log("in here");
                setFormData(defaultData);
              }}
            />
            <AppButton
              buttonType={"primary"}
              text={"Update"}
              loading={loading}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default AppSettings;

export const UpdateProfilePicture = () => {
  let { auth } = useSelector((state) => state),
    [logo, setLogo] = useState(false),
    [state, setState] = useState(auth?.user),
    [loading, setLoading] = useState(false),
    dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const user = useSelector(selectUser);
  console.log({ user });

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await axios.put(
        "/update-avatar",
        { avatar: logo },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log({ res });
      dispatch(setUser(res.data.user));
      setLogo(null);
      toast.success("user updated");
    } catch (error) {
      console.log({ error });
      toast.error(error.response?.data?.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (auth?.user) setState(auth?.user);
  }, [auth?.user]);

  let handleChangeImage = (e) => {
    const file = e.target.files[0];
    let err = "";

    if (!file) return (err = `File, ${file?.name} does not exist`);
    if (!file.type.includes("image"))
      return (err = `File, ${file?.name} format not supported`);

    if (err) {
      return toast.error(err);
    } else {
      setLogo(file);
    }
  };

  return (
    <div>
      <div className="flex items-center gap-6">
        <img
          src={logo ? URL.createObjectURL(logo) : user?.avatar || displayPic}
          alt="displayPic"
          className="h-[80px] w-[80px] rounded-full object-cover"
        />
        <AppButton
          buttonType={"outlineBlack"}
          text={logo ? "remove" : "change"}
          width={"norms"}
          onClick={() => {
            logo ? setLogo(null) : fileInputRef.current.click();
          }}
        />
        {logo && (
          <AppButton
            buttonType={"primary"}
            text={"update"}
            width={"norms"}
            onClick={() => {
              handleSubmit();
            }}
            loading={loading}
          />
        )}
        <input
          ref={fileInputRef}
          type="file"
          // accept="image/*"
          style={{ display: "none" }}
          onChange={handleChangeImage}
          name="image"
        />
      </div>
    </div>
  );
};
