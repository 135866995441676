import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  quotes: [],
  loading: false,
  isFetching: false,
  error: null,
};

const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    setQuote: (state, { payload }) => {
      state.quotes.push(payload);
    },
    removeQuote: (state, { payload }) => {
      state.quotes = cancelQuote(payload, state.quotes);
    },
    updateQuote: (state, { payload }) => {
      state.quotes = updateQuoteSlice(payload, state.quotes);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuotes.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchQuotes.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.quotes = payload;
    });
    builder.addCase(fetchQuotes.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.response?.data?.error;
    });
    builder.addCase(fetchAllQuotes.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllQuotes.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.quotes = payload;
    });
    builder.addCase(fetchAllQuotes.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error.response?.data?.error;
    });
  },
});

export const { setQuote, removeQuote, updateQuote } = quoteSlice.actions;
export default quoteSlice.reducer;

export const fetchQuotes = createAsyncThunk(
  "quote/fetchQuotes",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("/get-all-user-quotes");
      console.log({ res: res.data });
      const quotes = await res.data;
      return quotes;
    } catch (error) {
      console.log({ fetchQuotesError: error });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchAllQuotes = createAsyncThunk(
  "quote/fetchAllQuotes",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("/admin/get-all-quotes");
      console.log({ res });
      const quotes = await res.data;
      return quotes;
    } catch (error) {
      console.log({ fetchQuotesError: error });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const disApproveQuote = async (quoteId) => {
  const res = await axios.post(`service/disapprove-quote/${quoteId}`);
  console.log({ res });
};

const cancelQuote = (quote, quotes) => {
  const foundQuote = quotes.filter((item) => item._id === quote._id);
  return foundQuote;
};

const updateQuoteSlice = (quote, quotes) => {
  return quotes.map((item) => {
    if (item._id === quote._id) {
      return quote;
    }
    return item;
  });
};
