import { useNavigate, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectRole } from "../../data/store/selectors/userSelector";
import { MdLocationOn } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { IoStar } from "react-icons/io5";
import Brand from "../../components/brand/brand";
import { HiMenu } from "react-icons/hi";
import { useContext } from "react";
import { GlobalState } from "../../data/Context";
import userPic from "../../assets/img//user.jpg";
import Sidebar from "../../components/sidebar/sidebar";

const FindRequests = () => {
  const role = useSelector(selectRole);
  const { toggleNav, sidebarLinks } = useContext(GlobalState);
  const navigate = useNavigate();

  return (
    <Sidebar sidebarType={"reversed"}>
      <div className="min-h-screen">
        <div className="shadow-md fixed top-0 left-0 w-full z-20 bg-white">
          <div className="flex items-center justify-between p-6 lg:px-16">
            <Brand />
            <div className="md:hidden">
              <HiMenu
                className="h-8 w-8 text-gray-700 cursor-pointer"
                onClick={toggleNav}
              />
            </div>
            <div className="hidden md:flex items-center gap-12">
              <ul className="flex items-center gap-10">
                {role &&
                  sidebarLinks[role].map((link, idx) => (
                    <li key={idx} className="text-text cursor-pointer">
                      <NavLink
                        to={link.url}
                        className={({ isActive }) =>
                          isActive ? "font-bold text-main" : "font-medium"
                        }
                      >
                        {link.name}
                      </NavLink>
                    </li>
                  ))}
              </ul>
              <img
                src={userPic}
                alt=""
                className="rounded-full cursor-pointer h-10 w-10 object-cover"
                onClick={() => navigate("/settings")}
              />
            </div>
          </div>
          <div className="flex gap-x-16 md:gap-x-0 md:justify-between px-6 lg:px-16 py-4 overflow-x-auto scrollbar-hide">
            <div className="flex flex-shrink-0 md:w-[80%]">
              <div className="border-r pr-5 md:pr-0 border-lightGray flex-grow flex-shrink-0 space-y-3">
                <p className="text-dark text-sm">Service</p>
                <select
                  name="service"
                  id="service"
                  className="font-medium text-sm bg-transparent outline-none"
                >
                  <option value="room-cleaning">Room Cleaning</option>
                </select>
              </div>
              <div className="border-r pr-5 md:pr-0 border-lightGray flex-grow flex-shrink-0 pl-4 space-y-3">
                <p className="text-dark text-sm">Location</p>
                <p className="flex items-center font-medium text-sm gap-1">
                  <span className="text-main">
                    <MdLocationOn />
                  </span>
                  New York City, USA
                </p>
              </div>
              <div className="border-r pr-5 md:pr-0 border-lightGray flex-grow flex-shrink-0 pl-4 space-y-3">
                <p className="text-dark text-sm">Date</p>
                <p className="flex items-center font-medium text-sm gap-1">
                  <span className="text-main">
                    <FaCalendarAlt />
                  </span>
                  Anyday
                </p>
              </div>
              <div className="flex-grow flex-shrink-0 pl-4 space-y-3">
                <p className="text-dark text-sm">Price Range</p>
                <p className="flex items-center font-medium text-sm gap-1">
                  <span className="text-main">
                    <FaUser />
                  </span>
                  $10 - $20/hr
                </p>
              </div>
            </div>
            <button className="flex items-center gap-2 bg-main text-white font-semibold p-4 rounded-lg">
              <span>
                <FaSearch />
              </span>
              Search
            </button>
          </div>
        </div>
        <div className="grid lg:grid-cols-5 gap-10 pt-[140px] pb-14 px-6 md:px-10 lg:px-16">
          <div className="col-span-3 space-y-10 md:space-y-5">
            <FindRequestsCard />
            <FindRequestsCard />
            <FindRequestsCard />
            <FindRequestsCard />
            <FindRequestsCard />
          </div>
          <div className="col-span-2 w-full">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12481.610929769384!2d-74.01143097577781!3d40.71178662352774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sng!4v1718992462147!5m2!1sen!2sng"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default FindRequests;

const FindRequestsCard = () => {
  const navigate = useNavigate();

  return (
    <div
      className="md:flex gap-5 border border-lightGray w-full text-text text-sm md:pr-4 cursor-pointer"
      onClick={() => navigate("/find-requests/house-1")}
    >
      <img
        src={"../house.png"}
        alt=""
        className="flex-grow-0 w-full md:w-[195px] md:h-[170px]"
      />
      <div className="flex flex-grow justify-between md:items-center p-4 md:p-0">
        <div className="flex-grow">
          <h2 className="font-semibold text-black">
            4 bedroom apartment and bathroom
          </h2>
          <p className="mt-2">Room cleaning &bull; 4 rooms</p>
          <p className="mt-5 font-medium tracking-wide">
            <span className="text-black">Asher</span> &bull; 4 Orders completed
          </p>
          <p className="flex items-center gap-1 mt-2">
            <span>
              <IoStar className="text-[#FBBB5D]" />
            </span>
            4.5
          </p>
        </div>
        <div className="flex-grow-0 text-right">
          <p className="text-lg font-semibold text-black">$20/hr</p>
          <p className="">Posted 2 hours ago</p>
        </div>
      </div>
    </div>
  );
};
