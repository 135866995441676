import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SetAuthToken } from "../../Config";
import axios from "axios";
import {
  fetchAllServices,
  fetchContractorServices,
  fetchServices,
} from "./serviceSlice";
import { fetchContractors } from "./contractors";
import { fetchTenants } from "./tenantSlice";
import { fetchAllQuotes, fetchQuotes } from "./quoteSlice";

export const APP = "topSpot";
// const roles = 'admin' | 'tenant' | 'contractor' | 'owner'

const initialState = {
  isAuth: false,
  role: "",
  user: {},
  loading: false,
  error: null,
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, { payload }) => {
      if (payload.token) {
        localStorage.setItem(APP, payload.token);
        SetAuthToken(payload.token);
      }
      state.user = payload;
      state.role = payload.userType;
      state.isAuth = true;
    },
    logout: (state) => {
      state.isAuth = false;
      localStorage.removeItem(APP);
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(loadUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = payload;
      state.role = payload.userType;
      state.isAuth = true;
    });
    builder.addCase(loadUser.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });
  },
});

export const { login, logout, setUser } = UserSlice.actions;

export default UserSlice.reducer;

export const loadUser = createAsyncThunk(
  "user/loadUser",
  async (_, thunkAPI) => {
    const token = localStorage.getItem(APP);
    if (token) {
      SetAuthToken(token);
    }
    // try {
    try {
      const res = await axios.get("/current-user");
      console.log({ res });
      const user = await res.data.user;
      if (user.userType === "admin") {
        thunkAPI.dispatch(fetchContractors());
        thunkAPI.dispatch(fetchTenants());
        thunkAPI.dispatch(fetchAllServices());
        thunkAPI.dispatch(fetchAllQuotes());
      } else if (user.userType === "tenant") {
        thunkAPI.dispatch(fetchServices());
        thunkAPI.dispatch(fetchQuotes());
      } else if (user.userType === "contractor") {
        thunkAPI.dispatch(fetchContractorServices());
      }
      return user;
    } catch (error) {
      console.log({ loadUserError: error });
      return thunkAPI.dispatch(error);
    }
  }
);
