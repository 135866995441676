import React, { useEffect, useState } from "react";
import AppButton from "../../components/app-button/app-button.component";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Input, { Checkbox } from "../../components/input/input";
import { Controller, useForm } from "react-hook-form";
import {
  GetCountries,
  GetState,
  GetCity,
  GetLanguages, //async functions
} from "react-country-state-city";
import axios from "axios";
import { toast } from "react-toastify";

const Onboarding = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const step = location.search.split("=")[1];
  console.log(location.search);

  useEffect(() => {
    if (!location.search) {
      navigate("?step=1");
    }
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <PageController step={step} />
    </div>
  );
};

export default Onboarding;

const PageController = ({ step }) => {
  switch (step) {
    case "1":
      return <Location />;
    case "2":
      return <ServicePreference />;
    case "success":
      return <Success />;
    default:
      break;
  }
};

const Location = () => {
  const [countryId, setCountryId] = useState(0);
  const [stateid, setStateid] = useState(0);

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);

  // const [cityid, setCityid] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      country: "",
      state: "",
      postalCode: "",
    },
  });

  console.log({ countriesList });

  const onSubmit = async (data) => {
    console.log({ data });
    navigate(`/onboarding?stage=${2}`, { state: data });
  };

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);
  useEffect(() => {
    GetState(countryId).then((result) => {
      setStateList(result);
    });
  }, [countryId, getValues]);

  return (
    <form
      className="w-[90%] md:max-w-md mx-auto"
      onSubmit={handleSubmit(onSubmit)}
    >
      <article className="mb-4">
        <h1 className="text-2xl font-semibold">Set your Location</h1>
        <p className="text-text font-medium mt-2">
          Help us find the best services near you by setting your location below
        </p>
      </article>
      <div className="grid sm:grid-cols-2 gap-4">
        <div className="col-span-2">
          <Controller
            name="country"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Country"}
                type={"select"}
                selectHolder="select country"
                options={
                  countriesList.map((country) => country?.name) || [
                    "Loading...",
                  ]
                }
                value={value}
                onChange={(e) => {
                  onChange(e);
                  const newVal = e.target.value;
                  let selectedValue = countriesList.filter(
                    (country) => country.name === newVal
                  );
                  console.log({ selectedValue });
                  setCountryId(selectedValue[0]?.id);
                }}
              />
            )}
          />
          {errors.country && (
            <p className="text-red text-xs">{errors.country.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="state"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"State/Region"}
                type={"select"}
                selectHolder="select region"
                options={
                  stateList.map((country) => country?.name) || ["Loading..."]
                }
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.state && (
            <p className="text-red text-xs">{errors.state.message}</p>
          )}
        </div>
        <div className="col-span-2">
          <Controller
            name="postalCode"
            control={control}
            rules={{
              required: "This field is required",
            }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={"Postal Code"}
                placeholder="HT23K"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {errors.postalCode && (
            <p className="text-red text-xs">{errors.postalCode.message}</p>
          )}
        </div>
      </div>
      <div className="mt-4">
        <AppButton
          text={"Continue"}
          loading={loading}
          buttonType={"pill"}
          width={"full"}
        />
      </div>
    </form>
  );
};

const ServicePreference = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const form = location.state;
  console.log({ form });
  const navigate = useNavigate();
  const [services, setServices] = useState([]);

  const handleSelect = (e, value) => {
    if (e.target.checked) {
      setServices([...services, value.toLowerCase()]);
    } else {
      const mod = services.filter((service) => service !== value.toLowerCase());
      setServices(mod);
    }
  };

  const onSubmit = async () => {
    const data = {
      categories: services,
      ...form,
    };
    console.log({ data });
    setLoading(true);
    try {
      const res = await axios.put("/update", data);
      console.log({ res });
      toast.success(res.data.msg);
      navigate(`/onboarding?stage=success`);
    } catch (error) {
      console.log({ error });
      toast.error(error.response?.data?.error);
    }
    setLoading(false);
  };

  return (
    <div className="w-[90%] md:max-w-md mx-auto">
      <article className="mb-4">
        <h1 className="text-2xl font-semibold">
          Select Your Service Preferences
        </h1>
        <p className="text-text mt-2 font-medium">
          Tell us which services you're interested in, so we can match you with
          the right service providers
        </p>
      </article>
      <p className="font-semibold text-lg">Select</p>
      <div className="grid sm:grid-cols-2 md:gap-4 mt-4">
        <Checkbox
          label={"Plumbing"}
          onChange={(e) => handleSelect(e, "Plumbing")}
        />
        <Checkbox
          label={"Electrical Work"}
          onChange={(e) => handleSelect(e, "Electrical Work")}
        />
        <Checkbox
          label={"Painting"}
          onChange={(e) => handleSelect(e, "Painting")}
        />
        <Checkbox
          label={"Room Cleaning"}
          onChange={(e) => handleSelect(e, "Room Cleaning")}
        />
        <Checkbox
          label={"Furniture Assembly"}
          onChange={(e) => handleSelect(e, "Furniture Assembly")}
        />
      </div>
      <div className="mt-4">
        <AppButton
          text={"Continue"}
          loading={loading}
          buttonType={"pill"}
          width={"full"}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

// const Availability = () => {
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();
//   const [expertise, setExpertise] = useState([]);

//   const handleSelect = (e, value) => {
//     if (e.target.checked) {
//       setExpertise([...expertise, value.toLowerCase()]);
//     } else {
//       const mod = expertise.filter((ex) => ex !== value.toLowerCase());
//       setExpertise(mod);
//     }
//   };

//   const onSubmit = async () => {
//     console.log({ expertise });
//     navigate(`/register/onboarding?stage=success`);
//   };
//   return (
//     <div className="w-[90%] md:max-w-md mx-auto">
//       <article className="mb-4">
//         <h1 className="text-2xl font-semibold">Availability</h1>
//         <p className="text-text mt-2 font-medium">
//           What days and times are you available for appointments?
//         </p>
//       </article>
//       <div className="my-4">
//         <Input type={"date"} selectHolder={"years"} label={"Date Available"} />
//       </div>
//       <div className="mt-4">
//         <AppButton
//           text={"Continue"}
//           loading={loading}
//           buttonType={"pill"}
//           width={"full"}
//           onClick={onSubmit}
//         />
//       </div>
//     </div>
//   );
// };

const Success = () => {
  const navigate = useNavigate();

  const onSubmit = async () => {
    navigate("/");
  };

  return (
    <div className=" w-[90%] md:max-w-md mx-auto">
      <article className="mb-4 text-center space-y-6">
        <h1 className="text-2xl font-semibold">Complete</h1>
        <p className="text-text">
          Your account has been created. Follow the button below to access your
          dashboard and post a service you need as soon as possible
        </p>
      </article>
      <div className="mt-6">
        <AppButton
          text={"Proceed"}
          buttonType={"pill"}
          width={"full"}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};
