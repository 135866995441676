import { useNavigate } from "react-router-dom";
import AppButton from "../../components/app-button/app-button.component";
import Input from "../../components/input/input";
import Sidebar from "../../components/sidebar/sidebar";
import { useState } from "react";

const PostARequest = () => {
  const navigate = useNavigate();

  const [service, setService] = useState({
    name: "",
    categories: "",
    description: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setService({ ...service, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ service });
    navigate("/post-a-request/service-details?stage=1", { state: service });
  };
  return (
    <Sidebar sidebarType={"reversed"}>
      <section className="mx-auto my-10 md:my-20 md:mx-40 w-[80%] md:max-w-lg">
        <div>
          <h1 className="font-semibold text-2xl">Post a Service Request</h1>
          <p className="text-text text-[16px] mt-4 mb-6 font-medium">
            Tell us about the service you need, and we'll connect you with
            qualified service providers
          </p>
          <div className="space-y-6 mb-8">
            <Input
              label={"Give a name"}
              placeholder={"3-room apartment cleaning"}
              name={"name"}
              value={service.name}
              onChange={handleInput}
            />
            <Input
              label={"Select the category"}
              type={"select"}
              options={[
                "plumbing",
                "painting",
                "furniture assembly",
                "electrical work",
                "room cleaning",
                "other",
              ]}
              placeholder={"Select"}
              name={"categories"}
              // value={service.categories}
              onChange={handleInput}
            />
            <div className="mb-8">
              <Input
                label={"Describe your service"}
                type={"textArea"}
                placeholder="Give specific details that will describe your needs properly"
                value={service.description}
                name={"description"}
                onChange={handleInput}
              />
              <p className="text-text text-xs my-1 text-right">
                5,000 characters left
              </p>
            </div>
          </div>
          <AppButton
            buttonType={"pill"}
            text={"continue"}
            type="submit"
            width={"full"}
            onClick={handleSubmit}
          />
        </div>
      </section>
    </Sidebar>
  );
};

export default PostARequest;
