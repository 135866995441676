import { Route, Routes, useLocation } from "react-router-dom";
import PageRender from "./PageRender";
import Home from "./screens/index";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { auth, userSlice } from "./data/store/selectors/userSelector";
import Nav from "./components/nav/nav.component";
import Contractor from "./contractor/index";
import Admin from "./admin/index";
import Tenant from "./tenant";
import AppLoader from "./components/loader/AppLoader";

const Routers = () => {
  const location = useLocation();
  const isUnboarding = () => {
    if (
      location.pathname.split("/")[1] === "/login" ||
      location.pathname.split("/")[1] === "/register"
    ) {
      return true;
    } else {
      return false;
    }
  };
  console.log(isUnboarding());
  console.log({ path: location.pathname.split("/")[1] });
  const user = useSelector(userSlice);
  console.log({ auth });
  console.log({ user });

  if (user.loading) return <AppLoader />;
  return (
    <>
      <ToastContainer />
      {!user?.isAuth && <Nav />}
      <Routes>
        <Route
          path="/"
          element={
            user.isAuth ? (
              user.role === "tenant" ? (
                <Tenant />
              ) : user.role === "contractor" ? (
                <Contractor />
              ) : (
                "admin" && <Admin />
              )
            ) : (
              <Home />
            )
          }
        />
        <Route path="/:page" element={<PageRender />} />
        <Route path="/:page/:id" element={<PageRender />} />
        <Route path="/:page/:id/:step" element={<PageRender />} />
      </Routes>
      {/* {!user?.isAuth && !isUnboarding() && <Footer />} */}
      {/* <Footer /> */}
    </>
  );
};

export default Routers;
