import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ chartData, size = "medium" }) => {
  const sizeClasses = {
    small: "w-[4rem] h-[4rem] md:w-[5rem] md:h-[5rem]",
    medium: "w-24 h-24 md:w-28 md:h-28",
  };

  const paddingClasses = {
    small: "pb-12",
    medium: "pt-8 pb-20",
  };

  // Use the size prop to determine the classes
  const sizeClass = sizeClasses[size];
  const paddingClass = paddingClasses[size];

  return (
    <div className={`flex justify-around items-center ${paddingClass}`}>
      {chartData.map((data, index) => (
        <div
          key={index}
          className={`relative flex flex-col items-center ${sizeClass}`}
        >
          <Doughnut
            data={{
              labels: ["Completed", "Remaining"],
              datasets: [
                {
                  data: data.data,
                  backgroundColor: ["#17a2b8", "#e0e0e0"],
                  hoverBackgroundColor: ["#17a2b8", "#e0e0e0"],
                  borderWidth: 0,
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              cutout: "70%",
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
            }}
          />
          <div
            className={`absolute inset-0 flex items-center justify-center ${
              sizeClass == "font-medium" ? "text-lg" : ""
            } font-semibold`}
          >
            {data.label}
          </div>
          <p className="mt-4 text-sm text-text font-medium text-center">
            {data.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default DoughnutChart;
