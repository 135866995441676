import displayPic from "../assets/img/cleaning-service.png";
import AppButton from "../components/app-button/app-button.component";
import Input from "../components/input/input";
import AppSettings from "../components/settings/Settings";
import Sidebar from "../components/sidebar/sidebar";

const Settings = () => {
  return <AppSettings />;
};

export default Settings;
