import ApproveCompletedPage from "../../components/approve-complete-payment-page/approve-completed";
import Footer from "../../components/footer/footer";
import Sidebar from "../../components/sidebar/sidebar";

const pageDetails = {
  header: "Quote Accepted!",
  body: "Now that you have accepted the quote let's proceed to payment and get your project started as soon as possible!",
  buttonText: "Proceed to payments",
  navigateTo: "/pending-requests/payments",
};

const ApproveQuote = () => {
  return (
    <Sidebar sidebarType={"reversed"}>
      <section className="min-h-screen flex flex-col justify-center items-center">
        <ApproveCompletedPage pageDetails={pageDetails} />
      </section>
      <footer>
        <Footer />
      </footer>
    </Sidebar>
  );
};

export default ApproveQuote;
