import { useNavigate } from "react-router-dom";
import { ReactComponent as Approve } from "../../assets/svg/approve.svg";
import AppButton from "../../components/app-button/app-button.component";

const ApproveCompletedPage = ({ pageDetails }) => {
  const { header, body, buttonText, navigateTo } = pageDetails;

  const navigate = useNavigate();
  return (
    <>
      <div className="w-[86%] md:max-w-lg mx-auto">
        <Approve className="mx-auto my-[8px]" />
        <article className="mb-4 text-center space-y-6">
          <h1 className="text-2xl font-semibold">{header}</h1>
          <p className="text-text font-medium">{body}</p>
        </article>
        <div className="mt-6">
          <AppButton
            text={buttonText}
            buttonType={"pill"}
            width={"full"}
            onClick={() => navigate(navigateTo)}
          />
        </div>
      </div>
    </>
  );
};

export default ApproveCompletedPage;
