import { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { GlobalState } from "../../data/Context";
import { logout } from "../../data/store/reducers/userSlice";
import {
  selectRole,
  selectUser,
} from "../../data/store/selectors/userSelector";
import Brand from "../brand/brand";
import { ReactComponent as Settings } from "../../assets/svg/settings.svg";
import { ReactComponent as Logout } from "../../assets/svg/Logout.svg";
import userPic from "../../assets/img/user.jpg";
import { HiMenu } from "react-icons/hi";

const Sidebar = ({ children, sidebarType, bg }) => {
  const { sidebarLinks, nav, toggleNav } = useContext(GlobalState);
  const role = useSelector(selectRole);
  console.log({ role });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  if (sidebarType === "reversed") {
    return (
      <div className={`${bg ? bg : "bg-white"} relative`}>
        <div className="flex items-center justify-between p-6 lg:px-16">
          <Brand />
          <div className="md:hidden">
            <HiMenu
              className="h-8 w-8 text-gray-700 cursor-pointer"
              onClick={toggleNav}
            />
          </div>
          <div className="hidden md:flex items-center gap-12">
            <ul className="flex items-center gap-10">
              {role &&
                sidebarLinks[role]?.map((link, idx) => (
                  <li key={idx} className="text-text cursor-pointer">
                    <NavLink
                      to={link.url}
                      className={({ isActive }) =>
                        isActive ? "font-bold text-main" : "font-medium"
                      }
                    >
                      {link.name}
                    </NavLink>
                  </li>
                ))}
            </ul>
            <img
              src={user?.avatar || userPic}
              alt=""
              className="rounded-full cursor-pointer h-10 w-10 object-cover"
              onClick={() => navigate("/settings")}
            />
          </div>
        </div>
        {/* <div
          className={`fixed inset-0 z-40 bg-black bg-opacity-50 transition-opacity duration-300 ${
            nav ? "opacity-100 md:opacity-0" : "opacity-0 pointer-events-none"
          }`}
          onClick={toggleNav}
        ></div> */}
        <aside
          className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform shadow-xl bg-white ${
            nav ? "translate-x-0" : "-translate-x-full"
          } md:hidden`}
        >
          <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 scrollbar-hide">
            <div className="mb-12 mt-2 px-4">
              <Brand />
            </div>
            <ul className="space-y-4 font-medium mb-32">
              {sidebarLinks[role]?.map(({ name, url, icon }, idx) => (
                <DefaultLilnk name={name} url={url} icon={icon} key={idx} />
              ))}
            </ul>
            <div>
              <div className="absolute bottom-0 inset-x-0 py-12 space-y-4 bg-gray-50 p-5">
                <div className="flex flex-col gap-4">
                  <NavLink
                    to={"/settings"}
                    onClick={toggleNav}
                    className={({ isActive }) =>
                      `${
                        isActive && "bg-main text-white p-3"
                      } flex items-center rounded-lg space-x-4 text-text`
                    }
                  >
                    <Settings />
                    <p className="text-sm font-medium">Settings</p>
                  </NavLink>
                  <div
                    className="flex items-center space-x-4 cursor-pointer"
                    onClick={() => {
                      dispatch(logout());
                      navigate("/");
                    }}
                  >
                    <Logout />
                    <p className="text-text text-sm font-medium">Log out</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>
        <div className="">{children}</div>
      </div>
    );
  }

  return (
    <div className={``}>
      {/* <div
        className={`fixed inset-0 z-40 bg-black bg-opacity-50 transition-opacity duration-300 ${
          nav ? "opacity-100 md:opacity-0" : "opacity-0 pointer-events-none"
        }`}
        onClick={toggleNav}
      ></div> */}
      <button
        type="button"
        className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        onClick={toggleNav}
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform shadow-xl bg-white ${
          nav ? "translate-x-0" : "-translate-x-full sm:translate-x-0"
        }`}
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 scrollbar-hide">
          <div className="mb-12 mt-2 px-4">
            <Brand />
          </div>
          <ul className="space-y-4 font-medium mb-32">
            {sidebarLinks[role]?.map(({ name, url, icon }, idx) => (
              <DefaultLilnk name={name} url={url} icon={icon} key={idx} />
            ))}
          </ul>
          <div>
            <div className="absolute bottom-0 inset-x-0 py-12 space-y-4 bg-gray-50 p-5">
              <div className="flex flex-col gap-4">
                <NavLink
                  to={"/settings"}
                  onClick={toggleNav}
                  className={({ isActive }) =>
                    `${
                      isActive && "bg-main text-white p-3"
                    } flex items-center rounded-lg space-x-4 text-text`
                  }
                >
                  <Settings />
                  <p className="text-sm font-medium">Settings</p>
                </NavLink>
                <div
                  className="flex items-center space-x-4 cursor-pointer"
                  onClick={() => {
                    dispatch(logout());
                    navigate("/");
                  }}
                >
                  <Logout />
                  <p className="text-text text-sm font-medium">Log out</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>

      <div className={"sm:ml-64"}>{children}</div>
    </div>
  );
};

export default Sidebar;

const DropdownNavMenu = ({ name, links }) => {
  const [dropdown, setDropdown] = useState(false);
  return (
    <li>
      <button
        type="button"
        className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100"
        onClick={() => setDropdown(!dropdown)}
      >
        <svg
          className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 18 21"
        >
          <path d="M15 12a1 1 0 0 0 .962-.726l2-7A1 1 0 0 0 17 3H3.77L3.175.745A1 1 0 0 0 2.208 0H1a1 1 0 0 0 0 2h.438l.6 2.255v.019l2 7 .746 2.986A3 3 0 1 0 9 17a2.966 2.966 0 0 0-.184-1h2.368c-.118.32-.18.659-.184 1a3 3 0 1 0 3-3H6.78l-.5-2H15Z" />
        </svg>
        <span className="flex-1 ml-3 text-left whitespace-nowrap">{name}</span>
        <svg
          className="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      <ul className={`${!dropdown && "hidden"} space-y-2`}>
        {links.map((link, idx) => (
          <li key={idx}>
            <Link
              to={link.url}
              className="flex items-center w-full text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
            >
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

const DefaultLilnk = ({ name, url, icon }) => {
  const { toggleNav } = useContext(GlobalState);
  return (
    <li>
      <NavLink
        to={url}
        className={({ isActive }) =>
          `${
            isActive && "bg-main text-white"
          } flex items-center p-3 rounded-lg text-text group text-sm`
        }
        onClick={toggleNav}
      >
        {icon}
        <span className="flex-1 ml-3 whitespace-nowrap">{name}</span>
      </NavLink>
    </li>
  );
};
