import { Link } from "react-router-dom";

const ActionCard = ({ actionCard }) => {
  const { textHeader, text, link } = actionCard;

  return (
    <>
      <div className="p-4 rounded-2xl bg-white text-sm border border-text">
        <div className="m min-w-80">
          <p className="text-sm font-bold">{textHeader}</p>
          <p className="text-sm mt-2 mb-3 text-[#1E1E1E99]">{text}</p>
          <Link to={link} className="font-medium underline">
            {textHeader == "Check your Inbox" ? "Check inbox" : "Get started"}
          </Link>
        </div>
      </div>
    </>
  );
};

export default ActionCard;
