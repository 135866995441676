/* eslint-disable no-undef */
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { userSlice } from "./data/store/selectors/userSelector";
import { createElement, useEffect } from "react";

const generatePage = (pageName, folder) => {
  const component = () => require(`./${folder}/${pageName}`).default;
  try {
    return createElement(component());
  } catch (error) {
    // return <ErrorPage />;
  }
};

const PageRender = () => {
  const navigate = useNavigate();
  const user = useSelector(userSlice);
  const { page, id, step } = useParams();
  const escape2 = [
    "onboarding",
    "info",
    "info2",
    "approve-quote",
    "payments",
    "payments-approved",
    "service-details",
    "send-quote",
    "add-tenant",
    "assign-contractors",
    "create-quote",
  ];

  useEffect(() => {
    if (user?.loading && !user?.auth) {
      navigate("/");
      pageName = "/"
    }
  });

  let pageName = "";
  if (step) {
    pageName = `${page}/${id}/${"[id]"}`;
  } else if (id) {
    if (
      (page === "register" && escape2.includes(id)) ||
      (page === "page" && escape2.includes(id)) ||
      (page === "page1" && escape2.includes(id)) ||
      (page === "pending-requests" && escape2.includes(id)) ||
      (page === "post-a-request" && escape2.includes(id)) ||
      (page === "tenants" && escape2.includes(id)) ||
      (page === "find-requests" && escape2.includes(id))
    ) {
      pageName = `${page}/${id}`;
    } else {
      pageName = `${page}/${"[id]"}`;
    }
  } else {
    pageName = `${page}`;
  }

  return generatePage(
    pageName,
    user?.isAuth
      ? user.role === "tenant"
        ? "tenant"
        : user.role === "contractor"
        ? "contractor"
        : "admin"
      : "screens"
  );
};

export default PageRender;
