import { useNavigate } from "react-router-dom";
import TestimonialCard from "../components/testimonial-card/testimonial-card";
import List from "../components/list/list.component";
import AppButton from "../components/app-button/app-button.component";
import Footer from "../components/footer/footer";

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <>
      <header className="pt-32 pb-16 md:pb-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl text-center text-dark font-bold">
            Who We Are
          </h2>
        </div>
      </header>
      <section className="pb-20" id="mission">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-8 md:gap-16">
            <img
              src={require("../assets/img/mission.jpg")}
              alt="maintenance service"
              className="w-full md:w-[90%]"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            />
            <div
              className="self-center"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              <h5 className="text-xl font-bold text-main border-b border-gray pb-3">
                Our Mission
              </h5>
              <p className="text-text mt-6 text-sm">
                Topspot Ads & Property Management Inc. (TAPMI) is a full-service
                management firm dedicated to delivering top-tier real estate
                marketing, advertising, and property management solutions. We
                are proudly accredited by leading property developers across the
                country, providing essential marketing and advertising services
                that drive success both within and beyond the metro.
                Additionally, TAPMI assists clients with their financing needs,
                leveraging our strong partnerships with major commercial banks.
              </p>

              <p className="text-text mt-6 text-sm">
                Our management team consists of highly experienced brokers who
                specialize in real estate and media advertising, ensuring that
                our clients receive expert guidance and exceptional service. We
                are also affiliated with top outdoor advertising
                concessionaires, offering comprehensive marketing solutions for
                Static and LED Billboard sites.
              </p>
              <p className="text-text mt-6 text-sm">
                For over eight years, TAPMI has served as a trusted central
                reference point for clients, helping them connect with the
                market and elevate their businesses to new heights. Our
                commitment to excellence has earned us multiple awards in
                property marketing, management, and advertising.
              </p>
              <div className="mt-6">
                <AppButton
                  buttonType={"primary"}
                  text={"Explore Our Services"}
                  width={"se"}
                  onClick={() => navigate("/services")}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-20" id="our-values">
        <div
          className="container mx-auto px-4"
          data-aos="zoom-in-right"
          data-aos-duration="2000"
        >
          <div className="grid md:grid-cols-2 gap-16">
            <div className="self-center order-2 md:order-1">
              <h5 className="text-xl font-bold text-main">Our Values</h5>
              <ul className="mt-5 lg:mt-8 space-y-5 lg:space-y-10">
                <List
                  title={"Trust"}
                  text={
                    "We prioritize building trust between homeowners and service providers through verified reviews and transparent communication."
                  }
                />
                <List
                  title={"Quality"}
                  text={
                    "Our commitment to qualify ensures that every service provider meets high standards of excellence"
                  }
                />
                <List
                  title={"Community"}
                  text={
                    "We strive to create supportive community where homeowners and service providers can connect and collaborate"
                  }
                />
                <List
                  title={"Innovations"}
                  text={
                    "We continuously innovate to enhance the user experience and provide the best property management solutions"
                  }
                />
              </ul>
              <div className="mt-6">
                <AppButton
                  buttonType={"primary"}
                  width={"default"}
                  text={"Explore our services"}
                  onClick={() => navigate("/services")}
                />
              </div>
            </div>
            <img
              src={require("../assets/img/value.png")}
              alt="renovation service"
              className="order-1 md:order-2"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            />
          </div>
        </div>
      </section>
      {/* <section className="pt-8">
        <div
          className="container mx-auto px-4"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          <div className="bg-main p-12 rounded-xl">
            <h1 className="text-xl md:text-3xl text-center font-bold text-white">
              Get a service today
            </h1>
            <p className="font-light text-lg text-white mt-4 text-center max-w-2xl mx-auto">
              Ready to request a service? Contact us today to discuss your
              property management needs and schedule an appointment with our
              experienced team
            </p>
            <div className="flex justify-center gap-8 mt-8">
              <AppButton
                buttonType={"white"}
                text={"Get A Quote"}
                onClick={() => navigate("/get-a-quote")}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-14">
        <div
          className="container mx-auto px-4"
          data-aos="zoom-in-right"
          data-aos-duration="2000"
        >
          <h3 className="text-main font-bold text-2xl mb-2">
            Word from our clients
          </h3>
          <div className="overflow-x-auto p-4 flex items-center gap-8 scrollbar-hide">
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
          </div>
        </div>
      </section> */}
      <Footer />
    </>
  );
};

export default AboutUs;
