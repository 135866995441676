import { createSelector } from "@reduxjs/toolkit";

export const contractorSelector = (state) => state?.contractor;

export const selectContractors = createSelector(
  [contractorSelector],
  (contractors) => contractors?.contractors
);

export const contractorLoading = createSelector(
  [contractorSelector],
  (loading) => loading?.loading
);

export const contractorError = createSelector(
  [contractorSelector],
  (error) => error?.error
);
