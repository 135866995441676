import { useNavigate } from "react-router-dom";
import { GoDot } from "react-icons/go";

import AppButton from "../components/app-button/app-button.component";
import Card from "../components/card/card";
import TestimonialCard from "../components/testimonial-card/testimonial-card";

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <header className="bg-black py-24 md:py-32 relative">
        <div className="md:w-1/3 bg-main absolute bottom-0 md:right-0 w-full h-1/3 md:h-auto md:inset-y-0"></div>
        <div className="container mx-auto relative z-10 px-4">
          <div className="grid md:grid-cols-2 gap-8">
            <div
              className="text-white space-y-4 self-center"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            >
              <h1 className="text-3xl md:text-5xl font-bold">
                Welcome to TopSpot Your Trusted Property Solutions Hub
              </h1>
              <p>
                Your one-stop property management solution. Find or offer
                property management services with ease. Join us today!
              </p>
              <div className="flex items-center gap-4">
                <AppButton
                  buttonType={"white"}
                  text={"Sign up"}
                  onClick={() => navigate("/register")}
                />
                <AppButton
                  buttonType={"outline"}
                  text={"Login"}
                  onClick={() => navigate("/login")}
                />
              </div>
            </div>
            <div
              className="self-center"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <img
                src={require("../assets/img/hero.png")}
                alt="Hero"
                className="h-[440px] mx-auto"
              />
            </div>
          </div>
        </div>
      </header>
      <section className="py-10 pt-20">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center flex-wrap gap-3 md:gap-8">
            <div>
              <h5 className="text-2xl font-bold text-main">
                Discover Our Services
              </h5>
              <p className="text-text text-md max-w-xl my-3">
                Explore our comprehensive range of property services. Simplify
                your property management journey with us
              </p>
            </div>
            <div
              className="pill text-white w-fit px-8 py-2 rounded-full bg-main cursor-pointer"
              onClick={() => navigate("/services")}
            >
              View All
            </div>
          </div>
          <div
            className="mt-8 flex gap-8 w-full overflow-auto scrollbar-hide"
            data-aos="zoom-in-right"
            data-aos-duration="2000"
          >
            <Card img={"plumbing-card.png"} />
            <Card img={"painting-card.png"} />
            <Card img={"furniture-card.png"} />
            <Card img={"furniture-card.png"} />
          </div>
        </div>
      </section>
      <section className="pt-10 md:py-20">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-y-4 gap-x-10 xl:gap-8">
            <img
              src={require("../assets/img/how-it-works.png")}
              alt="How it works"
              className="w-[100%] h-[100%] object-cover"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            />
            <div data-aos="zoom-in-right" data-aos-duration="2000">
              <h5 className="text-2xl font-bold text-main">How it works</h5>
              <ul className="mt-4 space-y-8 xl:mt-8 xl:space-y-10">
                <li className="flex gap-2">
                  <span className="text-xl">
                    <GoDot />
                  </span>
                  <div>
                    <h6 className="font-bold text-dark text-lg">Sign up</h6>
                    <p className="text-lg text-text">
                      Create an account as a homeowner or service provider.
                    </p>
                  </div>
                </li>
                <li className="flex gap-2">
                  <span className="text-2xl">
                    <GoDot />
                  </span>
                  <div>
                    <h6 className="font-bold text-dark text-lg">
                      Explore Services
                    </h6>
                    <p className="text-lg text-text">
                      Find what you need with our easy search and filtering.
                    </p>
                  </div>
                </li>
                <li className="flex gap-2">
                  <span className="text-2xl">
                    <GoDot />
                  </span>
                  <div>
                    <h6 className="font-bold text-dark text-lg">
                      Book Services
                    </h6>
                    <p className="text-lg text-text">
                      Schedule appointments conveniently online.
                    </p>
                  </div>
                </li>
                <li className="flex gap-2">
                  <span className="text-2xl">
                    <GoDot />
                  </span>
                  <div>
                    <h6 className="font-bold text-dark text-lg">
                      Leave Feedback
                    </h6>
                    <p className="text-lg text-text">
                      Share your experience to help others.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-20 md:py-10">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-x-10 xl:gap-8">
            <img
              src="/why-choose-us.png"
              alt="How it works"
              className="w-[100%] h-[100%] object-cover"
              data-aos="zoom-in-right"
              data-aos-duration="2000"
            />
            <div data-aos="zoom-in-right" data-aos-duration="2000">
              <h5 className="text-2xl font-bold text-main mt-3 md:mt-0">
                Why choose us
              </h5>
              <ul className="mt-4 space-y-4 xl:mt-8 xl:space-y-10">
                <li className="flex gap-2">
                  <span className="text-2xl">
                    <GoDot />
                  </span>
                  <div>
                    <h6 className="font-bold text-dark text-lg">
                      Comprehensive Services
                    </h6>
                    <p className="text-lg text-text">
                      From plumbing to painting, find all your property needs in
                      one place.
                    </p>
                  </div>
                </li>
                <li className="flex gap-2">
                  <span className="text-2xl">
                    <GoDot />
                  </span>
                  <div>
                    <h6 className="font-bold text-dark text-lg">
                      Trusted Providers
                    </h6>
                    <p className="text-lg text-text">
                      Access vetted and top-rated service experts committed to
                      excellence.
                    </p>
                  </div>
                </li>
                <li className="flex gap-2">
                  <span className="text-2xl">
                    <GoDot />
                  </span>
                  <div>
                    <h6 className="font-bold text-dark text-lg">
                      User-Friendly Platform
                    </h6>
                    <p className="text-lg text-text">
                      Enjoy effortless booking and management with our intuitive
                      interface.
                    </p>
                  </div>
                </li>
                <li className="flex gap-2">
                  <span className="text-2xl">
                    <GoDot />
                  </span>
                  <div>
                    <h6 className="font-bold text-dark text-lg">
                      Transparent Pricing
                    </h6>
                    <p className="text-lg text-text">
                      No surprises—know upfront the cost of services with
                      transparency.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="py-14 md:py-10">
        <div
          className="container mx-auto px-4"
          data-aos="zoom-in-right"
          data-aos-duration="2000"
        >
          <h3 className="text-main font-bold text-2xl mb-2">
            Word from our clients
          </h3>
          <div className="overflow-x-auto p-4 flex items-center gap-8 scrollbar-hide">
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
            <TestimonialCard />
          </div>
        </div>
      </section> */}
      <section className="py-10 md:py-20">
        <div
          className="container mx-auto px-4 mb-8"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          <div className="bg-main p-8 rounded-xl">
            <h1 className=" text-4xl md:text-5xl text-center font-bold text-white">
              {/* Join Our Community Today */}
            </h1>
            <p className="font-light text-xl text-white mt-8 text-center max-w-lg mx-auto">
              Ready to simplify your property management? Sign up now and gain
              access to trusted service providers, exclusive benefits, and a
              supportive community at TopSpot!
            </p>
            <div className="flex justify-center gap-8 mt-12">
              <AppButton
                buttonType={"white"}
                text={"Sign up"}
                onClick={() => navigate("/register")}
              />
              <AppButton
                buttonType={"outline"}
                text={"login"}
                onClick={() => navigate("/login")}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
